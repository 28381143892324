import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useRouter } from 'found';
import { Theme } from 'helpers/theme';
import TOSHeader from 'components/Overview/TOSPage/TOSHeader';
import TOSContents, {
  LinkDetails,
} from 'components/Overview/TOSPage/TOSContents';
import PageMargin from 'components/core/PageMargin';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import CookiePolicy from './CookiePolicy';
import SubProcessors from './SubProcessors';

const useStyles = createUseStyles((theme: Theme) => ({
  privacyRoot: {
    width: '100%',
    backgroundColor: theme.colors.primaryWhite,
  },
  centered: {
    '@media (min-width: 700px)': {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
  },
  contents: {
    display: 'flex',
    flex: 1,
    margin: '0 20px 30px 0',
  },
  heading: {
    marginBottom: '65px',
  },
}));

const defaultLinks: LinkDetails[] = [
  { id: 0, title: 'Terms of Service', link: 'service' },
  {
    id: 1,
    title: 'Data Protection & Privacy Policy',
    href:
      'https://ttc-public-assets.s3.eu-west-1.amazonaws.com/Data+protection+and+privacy+policy+v1.0.pdf',
  },
  { id: 2, title: 'Cookie Policy', link: 'cookies' },
  { id: 3, title: 'List of Processors', link: 'sub-processors' },
  {
    id: 4,
    title: 'Appeals Policy',
    href:
      'https://ttc-public-assets.s3.eu-west-1.amazonaws.com/Appeals+policy+v1.0.pdf',
  },
  {
    id: 5,
    title: 'Complaints & Whistleblowing Policy',
    href:
      'https://ttc-public-assets.s3.eu-west-1.amazonaws.com/Complaints+and+whistleblowing+policy+v1.0.pdf',
  },
  {
    id: 6,
    title: 'Equality Policy',
    href:
      'https://ttc-public-assets.s3.eu-west-1.amazonaws.com/Equality+policy+v1.0.pdf',
  },
  {
    id: 7,
    title: 'Health & Safety Policy',
    href:
      'https://ttc-public-assets.s3.eu-west-1.amazonaws.com/Health+and+safety+policy+v1.0.pdf',
  },
  {
    id: 8,
    title: 'Malpractice Policy',
    href:
      'https://ttc-public-assets.s3.eu-west-1.amazonaws.com/Malpractice+policy+v1.0.pdf',
  },
];

type Props = {};

function ContentsPage(props: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { match } = useRouter();
  const { params } = match;
  const [selected, setSelected] = React.useState(defaultLinks[0]);

  const onChange = (link: LinkDetails) => {
    setSelected(link);
  };

  React.useEffect(() => {
    const link = defaultLinks.find(
      (x) => params?.policy && x.link === params?.policy,
    );
    if (link) {
      setSelected(link);
    } else {
      setSelected(defaultLinks[0]);
    }
  }, []);

  return (
    <div className={classes.privacyRoot}>
      <TOSHeader
        className={classes.heading}
        title="Policies and Procedures"
        subtitle="We’re committed to keeping your data secure, your private information private, and being transparent about our practices as a business."
      />
      <PageMargin centeredStyle={classes.centered}>
        <TOSContents
          className={classes.contents}
          links={defaultLinks}
          selected={selected}
          setSelected={onChange}
        />
        <div style={{ flex: 3 }}>
          {selected === defaultLinks[0] && <TermsOfService />}
          {selected === defaultLinks[2] && <CookiePolicy />}
          {selected === defaultLinks[3] && <SubProcessors />}
        </div>
      </PageMargin>
    </div>
  );
}

export default ContentsPage;
