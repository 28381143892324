/* tslint:disable */
/* eslint-disable */
/* @relayHash 52d2b311e3bb53a102d6fa415654c22b */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_CourseDetailsPage_QueryVariables = {
    id: number;
    afterDate?: string | null;
};
export type App_CourseDetailsPage_QueryResponse = {
    readonly course: {
        readonly " $fragmentRefs": FragmentRefs<"CourseDetailsPage_course">;
    } | null;
    readonly user: {
        readonly " $fragmentRefs": FragmentRefs<"CourseDetailsPage_user">;
    } | null;
    readonly popularCourses: ReadonlyArray<{
        readonly " $fragmentRefs": FragmentRefs<"CourseDetailsPage_popularCourses">;
    }> | null;
};
export type App_CourseDetailsPage_Query = {
    readonly response: App_CourseDetailsPage_QueryResponse;
    readonly variables: App_CourseDetailsPage_QueryVariables;
};



/*
query App_CourseDetailsPage_Query(
  $id: Int!
  $afterDate: Time
) {
  course(id: $id) {
    __typename
    ...CourseDetailsPage_course_3I6gVe
  }
  user(noError: true) {
    ...CourseDetailsPage_user
  }
  popularCourses {
    __typename
    ...CourseDetailsPage_popularCourses
  }
}

fragment CourseDetailsPage_course_3I6gVe on Course {
  ident: id
  name
  updatedAt
  price
  excerpt
  introduction
  bannerImageURL
  howToComplete
  hoursToComplete
  whatYouLearn
  requirements
  allowedToBuy
  accessType
  category {
    name
    color
  }
  secondaryCategory {
    name
  }
  ... on ClassroomCourse {
    instances(filter: {afterDate: $afterDate}) {
      edges {
        startDate
        endDate
        location
      }
    }
  }
}

fragment CourseDetailsPage_popularCourses on Course {
  ident: id
  name
  price
  excerpt
  introduction
  bannerImageURL
  category {
    name
    color
  }
}

fragment CourseDetailsPage_user on User {
  firstName
  type
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "Int!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "afterDate",
            "type": "Time",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any)
    ], v2 = [
        ({
            "kind": "Variable",
            "name": "afterDate",
            "variableName": "afterDate"
        } as any)
    ], v3 = [
        ({
            "kind": "Literal",
            "name": "noError",
            "value": true
        } as any)
    ], v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "__typename",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": "ident",
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "price",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "excerpt",
        "args": null,
        "storageKey": null
    } as any), v9 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "introduction",
        "args": null,
        "storageKey": null
    } as any), v10 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "bannerImageURL",
        "args": null,
        "storageKey": null
    } as any), v11 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "category",
        "storageKey": null,
        "args": null,
        "concreteType": "Category",
        "plural": false,
        "selections": [
            (v6 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "color",
                "args": null,
                "storageKey": null
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_CourseDetailsPage_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "course",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "CourseDetailsPage_course",
                            "args": (v2 /*: any*/)
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": "user(noError:true)",
                    "args": (v3 /*: any*/),
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "CourseDetailsPage_user",
                            "args": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "popularCourses",
                    "storageKey": null,
                    "args": null,
                    "concreteType": null,
                    "plural": true,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "CourseDetailsPage_popularCourses",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_CourseDetailsPage_Query",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "course",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "updatedAt",
                            "args": null,
                            "storageKey": null
                        },
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        (v10 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "howToComplete",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "hoursToComplete",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "whatYouLearn",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "requirements",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "allowedToBuy",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "accessType",
                            "args": null,
                            "storageKey": null
                        },
                        (v11 /*: any*/),
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "secondaryCategory",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "SecondaryCategory",
                            "plural": false,
                            "selections": [
                                (v6 /*: any*/)
                            ]
                        },
                        {
                            "kind": "InlineFragment",
                            "type": "ClassroomCourse",
                            "selections": [
                                {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "instances",
                                    "storageKey": null,
                                    "args": [
                                        {
                                            "kind": "ObjectValue",
                                            "name": "filter",
                                            "fields": (v2 /*: any*/)
                                        }
                                    ],
                                    "concreteType": "ClassroomInstancePage",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "kind": "LinkedField",
                                            "alias": null,
                                            "name": "edges",
                                            "storageKey": null,
                                            "args": null,
                                            "concreteType": "ClassroomInstance",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "startDate",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "endDate",
                                                    "args": null,
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "ScalarField",
                                                    "alias": null,
                                                    "name": "location",
                                                    "args": null,
                                                    "storageKey": null
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": "user(noError:true)",
                    "args": (v3 /*: any*/),
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "type",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "popularCourses",
                    "storageKey": null,
                    "args": null,
                    "concreteType": null,
                    "plural": true,
                    "selections": [
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        (v10 /*: any*/),
                        (v11 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_CourseDetailsPage_Query",
            "id": null,
            "text": "query App_CourseDetailsPage_Query(\n  $id: Int!\n  $afterDate: Time\n) {\n  course(id: $id) {\n    __typename\n    ...CourseDetailsPage_course_3I6gVe\n  }\n  user(noError: true) {\n    ...CourseDetailsPage_user\n  }\n  popularCourses {\n    __typename\n    ...CourseDetailsPage_popularCourses\n  }\n}\n\nfragment CourseDetailsPage_course_3I6gVe on Course {\n  ident: id\n  name\n  updatedAt\n  price\n  excerpt\n  introduction\n  bannerImageURL\n  howToComplete\n  hoursToComplete\n  whatYouLearn\n  requirements\n  allowedToBuy\n  accessType\n  category {\n    name\n    color\n  }\n  secondaryCategory {\n    name\n  }\n  ... on ClassroomCourse {\n    instances(filter: {afterDate: $afterDate}) {\n      edges {\n        startDate\n        endDate\n        location\n      }\n    }\n  }\n}\n\nfragment CourseDetailsPage_popularCourses on Course {\n  ident: id\n  name\n  price\n  excerpt\n  introduction\n  bannerImageURL\n  category {\n    name\n    color\n  }\n}\n\nfragment CourseDetailsPage_user on User {\n  firstName\n  type\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '501658036ece8637ae3ab87e948c8ea8';
export default node;
