import * as React from "react";
import ReCAPTCHA from "react-google-recaptcha";

type Props = {
  onChange: (token: string) => void;
}

function Recaptcha({ onChange }: Props){
  return (
    <ReCAPTCHA
      sitekey="6Lf5ncMZAAAAABOFglW482gfHiqlTnZfAHihHHVD"
      onChange={onChange}
    />
  );
}

export default Recaptcha;