import * as React from 'react';
//@ts-ignore
import { BrowserProtocol, queryMiddleware } from 'farce';
import { createFarceRouter, createRender, makeRouteConfig, Route } from 'found';
//@ts-ignore
import { Resolver } from 'found-relay';
import environment from './api/environment';
import { graphql, createFragmentContainer } from 'react-relay';
import { ThemeProvider } from 'react-jss';
import theme from './helpers/theme';
import AppHolder from 'views/AppHolder';
import { Redirect } from 'react-router-dom';
import Home from 'views/Home';
import AboutUs from 'views/AboutUs';
import RegisterStart from 'views/RegisterStart';
import RegisterIndividual from 'views/RegisterIndividual';
import RegisterCompany from 'views/RegisterCompany';
import Courses from 'views/Courses';
import Consultancy from 'views/Consultancy';
import CourseDetailsPage from 'views/CourseDetailsPage';
import ContactUs from 'views/ContactUs';
import RegisterCalendar from 'views/RegisterCalendar';
import ArticleLandingPage from 'views/ArticleLandingPage';
import Article from 'views/Article';
import CompanyFeatures from 'views/CompanyFeatures';
import IndividualFeatures from 'views/IndividualFeatures';
import ExploreCourses from 'views/ExploreCourses';
import ContentsPage from 'views/Policies/ContentsPage';
import moment from 'moment';

const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware],
  routeConfig: makeRouteConfig(
    <Route>
      <Route
        Component={AppHolder}
        //query={ExamplePageQuery}
        query={graphql`
          query App_AppHolder_Query {
            categories {
              ...AppHolder_categories
            }
            user(noError: true) {
              ...AppHolder_user
            }
          }
        `}
        render={({ props, error }: any) => {
          if (!props) {
            return <div></div>;
          }
          if (!props.match.location.pathname.includes('/courses/'))
            window.scrollTo(0, 0);
          return <AppHolder {...props} />;
        }}
      >
        <Route
          path="/"
          Component={Home}
          query={graphql`
            query App_Home_Query {
              popularCourses {
                ...Home_popularCourses
              }
            }
          `}
        />
        <Route path="/companyfeatures" Component={CompanyFeatures} />
        <Route path="/individualfeatures" Component={IndividualFeatures} />
        <Route path="/aboutus" Component={AboutUs} />
        <Route
          path="/courses"
          Component={ExploreCourses}
          query={graphql`
            query App_ExploreCourses_Query {
              popularCourses {
                ...ExploreCourses_popularCourses
              }
              categories {
                ...ExploreCourses_categories
              }
            }
          `}
        />
        <Route
          path="/course/:id"
          Component={CourseDetailsPage}
          query={graphql`
            query App_CourseDetailsPage_Query($id: Int!, $afterDate: Time) {
              course(id: $id) {
                ...CourseDetailsPage_course @arguments(afterDate: $afterDate)
              }
              user(noError: true) {
                ...CourseDetailsPage_user
              }
              popularCourses {
                ...CourseDetailsPage_popularCourses
              }
            }
          `}
          prepareVariables={(params: any) => {
            const { id } = params;
            return {
              id: parseInt(id),
              afterDate: new Date(),
            };
          }}
        />
        <Route
          path="/courses/:categoryUUID/:secondaryCategoryUUID?"
          Component={Courses}
          query={graphql`
            query App_Courses_Query(
              $page: Page
              $filter: CoursesFilter
              $categoryUUID: UUID!
              $orderBy: OrderBy
            ) {
              courses(page: $page, filter: $filter, orderBy: $orderBy) {
                ...Courses_courses
              }
              category(uuid: $categoryUUID) {
                ...Courses_category
              }
              secondaryCategories {
                ...Courses_secondaryCategories
              }
            }
          `}
          prepareVariables={(params: any, { location }: any) => {
            let { categoryUUID, secondaryCategoryUUID } = params;
            const _params = {
              ...params,
              page: {
                offset: 0,
                limit: 5,
              },
              filter: {
                categoryUUID,
                secondaryCategoryUUID: secondaryCategoryUUID ? secondaryCategoryUUID : undefined,
                role: location.query.role != "Show All" ? location.query.role : undefined,
                timeframe: location.query.timeframe != "Show All" ? location.query.timeframe : undefined,
              },
              orderBy: {
                ascending: false,
                field: 'priority',
              },
              categoryUUID,
            };
            return _params
          }}
        />
        <Route path="/consultancy" Component={Consultancy} />
        <Route path="/contact" Component={ContactUs} />
        <Route path="/policies/:policy?" Component={ContentsPage} />
        <Route 
          path="/articles" 
          Component={ArticleLandingPage}
          query={graphql`
            query App_ArticleLandingPage_Query($page: Page, $orderBy: OrderBy){
              blogs(page: $page, orderBy: $orderBy){
                edges {
                  uuid
                  title
                  createdAt
                  title
                  headerImageURL
                  category {
                    name
                    color
                  }
                }
              }
            }
          `}
          prepareVariables={() => {
            return {
              page: {
                limit: 60
              }
            }
          }}
          render={(args: any) => {
            if (!args.props) return <div></div>;
            return (
              <ArticleLandingPage articles={args.props.blogs.edges ?? []} />
            );
          }}
        />
        <Route 
          path="/article/:id" 
          Component={Article}
          query={graphql`
            query App_Article_Query($id: UUID!){
              blog(uuid: $id){
                uuid
                title
                body
                createdAt
                category {
                  name
                }
                headerImageURL
              }
            }
          `}
          prepareVariables={(params: any) => {
            const { id } = params;
            return {
              id
            };
          }}
          render={(args: any) => {
            if (!args.props) return <div></div>;
            return (
              <Article blog={args.props.blog} />
            );
          } }
        />
      </Route>
      <Route path="/register" Component={RegisterStart} />
      <Route path="/register/individual" Component={RegisterIndividual} />
      <Route path="/register/company" Component={RegisterCompany} />
      <Route path="/register/calendar" Component={RegisterCalendar} />
    </Route>,
  ),
  render: createRender({}),
});

const App = () => (
  <ThemeProvider theme={theme}>
    <Router resolver={new Resolver(environment)} />
  </ThemeProvider>
);

export default App;
