/* tslint:disable */
/* eslint-disable */
/* @relayHash 25fdd11a88b8b04535bb5939d0a455a5 */

import { ConcreteRequest } from "relay-runtime";
export type ContactUs_ContactAdminMutationVariables = {
    recaptchaToken: string;
    firstName: string;
    lastName: string;
    companyName: string;
    email: string;
    phone: string;
    details: string;
};
export type ContactUs_ContactAdminMutationResponse = {
    readonly contactAdmin: boolean;
};
export type ContactUs_ContactAdminMutation = {
    readonly response: ContactUs_ContactAdminMutationResponse;
    readonly variables: ContactUs_ContactAdminMutationVariables;
};



/*
mutation ContactUs_ContactAdminMutation(
  $recaptchaToken: String!
  $firstName: String!
  $lastName: String!
  $companyName: String!
  $email: String!
  $phone: String!
  $details: String!
) {
  contactAdmin(input: {recaptchaToken: $recaptchaToken, firstName: $firstName, lastName: $lastName, companyName: $companyName, email: $email, phone: $phone, details: $details})
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "recaptchaToken",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "firstName",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "lastName",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "companyName",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "email",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "phone",
            "type": "String!",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "details",
            "type": "String!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "ScalarField",
            "alias": null,
            "name": "contactAdmin",
            "args": [
                {
                    "kind": "ObjectValue",
                    "name": "input",
                    "fields": [
                        {
                            "kind": "Variable",
                            "name": "companyName",
                            "variableName": "companyName"
                        },
                        {
                            "kind": "Variable",
                            "name": "details",
                            "variableName": "details"
                        },
                        {
                            "kind": "Variable",
                            "name": "email",
                            "variableName": "email"
                        },
                        {
                            "kind": "Variable",
                            "name": "firstName",
                            "variableName": "firstName"
                        },
                        {
                            "kind": "Variable",
                            "name": "lastName",
                            "variableName": "lastName"
                        },
                        {
                            "kind": "Variable",
                            "name": "phone",
                            "variableName": "phone"
                        },
                        {
                            "kind": "Variable",
                            "name": "recaptchaToken",
                            "variableName": "recaptchaToken"
                        }
                    ]
                }
            ],
            "storageKey": null
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "ContactUs_ContactAdminMutation",
            "type": "Mutation",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "ContactUs_ContactAdminMutation",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "mutation",
            "name": "ContactUs_ContactAdminMutation",
            "id": null,
            "text": "mutation ContactUs_ContactAdminMutation(\n  $recaptchaToken: String!\n  $firstName: String!\n  $lastName: String!\n  $companyName: String!\n  $email: String!\n  $phone: String!\n  $details: String!\n) {\n  contactAdmin(input: {recaptchaToken: $recaptchaToken, firstName: $firstName, lastName: $lastName, companyName: $companyName, email: $email, phone: $phone, details: $details})\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'aab00e68db4ca919f99022ba0800b078';
export default node;
