/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Courses_category = {
    readonly uuid: string | null;
    readonly name: string;
    readonly description: string;
    readonly color: string;
    readonly faqItems: ReadonlyArray<{
        readonly question: string;
        readonly answer: string;
    }> | null;
    readonly " $refType": "Courses_category";
};
export type Courses_category$data = Courses_category;
export type Courses_category$key = {
    readonly " $data"?: Courses_category$data;
    readonly " $fragmentRefs": FragmentRefs<"Courses_category">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "Courses_category",
    "type": "Category",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "uuid",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "color",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "faqItems",
            "storageKey": null,
            "args": null,
            "concreteType": "FAQItem",
            "plural": true,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "question",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "answer",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = '3a3c16e408218218c4b9fe7ae63f52f2';
export default node;
