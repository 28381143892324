import * as React from 'react';
import classNames from 'classnames';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';

const useStyles = createUseStyles((theme: Theme) => ({
  pageHeading: {
    fontSize: theme.fontSizes.extraLargeHeading,
    color: theme.colors.primaryBlack,
    fontWeight: '800',
  },
  pageSubheading: {
    fontSize: theme.fontSizes.xSmallHeading,
    color: theme.colors.textGrey,
    fontWeight: '200',
    margin: '15px 0',
  },
  lastUpdated: {
    fontSize: theme.fontSizes.default,
    color: theme.colors.textGrey,
    fontWeight: '200',
    paddingBottom: '10px',
    borderBottom: ['1px', 'solid', '#D8D8D8'],
    marginBottom: '25px',
  },
  textHeading: {
    fontSize: theme.fontSizes.heading,
    color: theme.colors.primaryBlack,
    fontWeight: '800',
    margin: '30px 0 10px 0',
  },
  text: {
    fontSize: theme.fontSizes.large,
    color: '#404349',
    fontWeight: '200',
    margin: '17px 0',
  },
  bold: {
    fontWeight: 'bold',
    minWidth: '150px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: '50px',
  },
  marginBottom: {
    marginBottom: '100px',
  },
  bulletFilled: {
    minHeight: '8px',
    minWidth: '8px',
    borderRadius: '8px',
    backgroundColor: theme.colors.textBlue,
    margin: '20px',
  },
  bulletEmpty: {
    minHeight: '8px',
    minWidth: '8px',
    borderRadius: '8px',
    border: ['1px', 'solid', theme.colors.textBlue],
    margin: '20px 20px 20px 40px',
  },
  centered: {
    '@media (max-width: 700px)': {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
  },
}));

type Props = {};

function CookiePolicy(props: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.centered}>
      <div className={classes.pageHeading}>Cookie Policy</div>
      <div className={classes.pageSubheading}>
        Policies that apply to your account and use of this site
      </div>
      <div className={classes.lastUpdated}>
        Last revised on the 1st Jan 2023, and effective as of that date
      </div>
      <div className={classes.text}>
        www.ttc-hub.com (Our Site) uses Cookies and similar technologies in
        order to distinguish you from other users. By using Cookies, we are able
        to provide you with a better experience and to improve Our Site by
        better understanding how you use it. Please read this Cookie Policy
        carefully and ensure that you understand it. Your acceptance of Our
        Cookie Policy is deemed to occur if you continue using Our Site. If you
        do not agree to Our Cookie Policy, please stop using Our Site
        immediately.
      </div>
      <div className={classes.textHeading}>
        1) Definitions and Interpretation
      </div>
      <div className={classes.text}>
        <strong>1.1</strong> In this Cookie Policy, unless the context otherwise
        requires, the following expressions have the following meanings:
      </div>
      <div className={classes.row}>
        <div className={classes.bold}>“Cookie”</div>
        <div className={classes.text}>
          means a small file placed on your computer or device by Our Site when
          you visit certain parts of Our Site and/or when you use certain
          features of Our Site;
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.bold}>“Cookie Law”</div>
        <div className={classes.text}>
          means the UK GDPR (the retained EU law version of the General Data
          Protection Regulation ((EU) 2016/679) and the Data Protection Act 2018
          (DPA 2018) (“GDPR”);
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.bold}>"personal data”</div>
        <div className={classes.text}>
          means any and all data that relates to an identifiable person who can
          be directly or indirectly identified from that data, as defined by
          GDPR; and
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.bold}>“We/Us/Our”</div>
        <div className={classes.text}>
          means The Training and Compliance Hub Limited, a limited company
          registered in England under company number 10849230, whose registered
          address is Plesman House, 2a Cains Lane, Feltham, Middlesex, TW14 9RL.
        </div>
      </div>
      <div className={classes.textHeading}>2) Information About Us</div>
      <div className={classes.text}>
        <strong>2.1</strong> www.ttc-hub.com is owned and operated by Us.{' '}
      </div>
      <div className={classes.text}>
        <strong>2.2</strong> Our VAT number is 272 4889 65.{' '}
      </div>
      <div className={classes.textHeading}>
        3) How Does Our Site Use Cookies?
      </div>
      <div className={classes.text}>
        <strong>3.1</strong> www.ttc-hub.com may place and access certain first
        party Cookies on your computer or device. First party Cookies are those
        placed directly by Us and are used only by Us. We use Cookies to
        facilitate and improve your experience of Our Site and to provide and
        improve Our services. We have carefully chosen these Cookies and have
        taken steps to ensure that your privacy and personal data is protected
        and respected at all times.{' '}
      </div>
      <div className={classes.text}>
        <strong>3.2</strong> By using Our Site, you may also receive certain
        third-party Cookies on your computer or device. Third party Cookies are
        those placed by websites, services, and/or parties other than Us.{' '}
      </div>
      <div className={classes.text}>
        <strong>3.3</strong> All Cookies used by and on Our Site are used in
        accordance with current Cookie Law. We may use some or all of the
        following types of Cookie:
      </div>
      <div className={classes.marginLeft}>
        <div className={classes.text}>
          <strong>3.3.1 Strictly Necessary Cookies</strong>
          <br></br>
          <br></br>A Cookie falls into this category if it is essential to the
          operation of Our Site, supporting functions such as logging in, your
          shopping basket, and payment transactions.
        </div>
        <div className={classes.text}>
          <strong>3.3.2 Analytics Cookies</strong>
          <br></br>
          <br></br>It is important for Us to understand how you use Our Site,
          for example, how efficiently you are able to navigate around it, and
          what features you use. Analytics Cookies enable us to gather this
          information, helping Us to improve Our Site and your experience of it.
        </div>
        <div className={classes.text}>
          <strong>3.3.3 Functionality Cookies</strong>
          <br></br>
          <br></br>Functionality Cookies enable Us to provide additional
          functions to you on Our Site such as personalisation and remembering
          your saved preferences. Some functionality Cookies may also be
          strictly necessary Cookies, but not all necessarily fall into that
          category.
        </div>
        <div className={classes.text}>
          <strong>3.3.4 Targeting Cookies</strong>
          <br></br>
          <br></br>It is important for Us to know when and how often you visit
          Our Site, and which parts of it you have used (including which pages
          you have visited and which links you have visited). As with analytics
          Cookies, this information helps us to better understand you and, in
          turn, to make Our Site and advertising more relevant to your
          interests. Some information gathered by targeting Cookies may also be
          shared with third parties.
        </div>
        <div className={classes.text}>
          <strong>3.3.5 Persistent Cookies</strong>
          <br></br>
          <br></br>Any of the above types of Cookie may be a persistent Cookie.
          Persistent Cookies are those which remain on your computer or device
          for a predetermined period and are activated each time you visit Our
          Site.
        </div>
        <div className={classes.text}>
          <strong>3.3.6 Session Cookies</strong>
          <br></br>
          <br></br>Any of the above types of Cookie may be a session Cookie.
          Session Cookies are temporary and only remain on your computer or
          device from the point at which you visit Our Site until you close your
          browser. Session Cookies are deleted when you close your browser.
        </div>
      </div>
      <div className={classes.text}>
        <strong>3.4</strong> For more details of the personal data that We
        collect and use, the measures we have in place to protect personal data,
        your legal rights, and our legal obligations, please refer to our
        Privacy Policy.
      </div>
      <div className={classes.text}>
        <strong>3.5</strong> For more specific details of the Cookies that We
        use, please refer to the table below.
      </div>
      <div className={classes.textHeading}>
        4) What Cookies Does Our Site Use?
      </div>
      <div className={classes.text}>
        <strong>4.1</strong> Some of the cookies we use are{' '}
        <strong>necessary</strong> to enable you to move around the Website and
        use its features.{' '}
      </div>
      <div className={classes.text}>
        <strong>4.2</strong> We also use <strong>functional cookies</strong> to
        record information about the choices you have made.{' '}
      </div>
      <div className={classes.text}>
        <strong>4.3</strong> We or our service providers also use analytic
        services to help us understand how effective our content is, what
        interests our users have, and to improve how this Website works. In
        addition, we use web beacons or tracking pixels to count visitor numbers
        and <strong>performance cookies</strong> to track how many individual
        users access this Website and how often. This information is used for
        statistical purposes only and it is not our intention to use such
        information to personally identify any user. However, if you have
        registered into this Website we may combine this information with
        information from our web analytic services and cookies to analyse how
        you use this Website in more detail.{' '}
      </div>
      <div className={classes.textHeading}>5) Consent and Controls</div>
      <div className={classes.text}>
        <strong>5.1</strong> Before Cookies are placed on your computer or
        device, you will be shown a pop up requesting your consent to set those
        Cookies. By giving your consent to the placing of Cookies you are
        enabling Us to provide the best possible experience and service to you.
        You may, if you wish, deny consent to the placing of Cookies unless
        those Cookies are strictly necessary; however certain features of Our
        Site may not function fully or as intended.{' '}
      </div>
      <div className={classes.text}>
        <strong>5.2</strong> In addition to the controls that We provide, you
        can choose to enable or disable Cookies in your internet browser. Most
        internet browsers also enable you to choose whether you wish to disable
        all Cookies or only third-party Cookies. By default, most internet
        browsers accept Cookies but this can be changed. For further details,
        please consult the help menu in your internet browser or the
        documentation that came with your device.{' '}
      </div>
      <div className={classes.text}>
        <strong>5.3</strong> The links below provide instructions on how to
        control Cookies in all mainstream browsers:
      </div>
      <div className={classes.marginLeft}>
        <div className={classes.text}>
          <strong>5.3.1 Google Chrome: </strong>
          <br></br>
          <br></br>
          <a href="https://support.google.com/chrome/answer/95647?hl=en-GB">
            https://support.google.com/chrome/answer/95647?hl=en-GB
          </a>
        </div>
        <div className={classes.text}>
          <strong>5.3.2 Microsoft Internet Explorer:</strong>
          <br></br>
          <br></br>
          <a href="https://support.microsoft.com/en-us/kb/278835">
            https://support.microsoft.com/en-us/kb/278835
          </a>
        </div>
        <div className={classes.text}>
          <strong>5.3.3 Microsoft Edge:</strong>
          <br></br>
          <br></br>
          <a href="https://support.microsoft.com/en-gb/products/microsoft-edge ">
            https://support.microsoft.com/en-gb/products/microsoft-edge{' '}
          </a>{' '}
          (Please note that there are no specific instructions at this time, but
          Microsoft support will be able to assist)
        </div>
        <div className={classes.text}>
          <strong>5.3.4 Safari (macOS):</strong>
          <br></br>
          <br></br>
          <a href="https://support.apple.com/kb/PH21411?viewlocale=en_GB&locale=en_GB">
            https://support.apple.com/kb/PH21411?viewlocale=en_GB&locale=en_GB
          </a>
        </div>
        <div className={classes.text}>
          <strong>5.3.5 Safari (iOS): </strong>
          <br></br>
          <br></br>
          <a href="https://support.apple.com/en-gb/HT201265">
            https://support.apple.com/en-gb/HT201265
          </a>
        </div>
        <div className={classes.text}>
          <strong>5.3.6 Mozilla Firefox:</strong>
          <br></br>
          <br></br>
          <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences">
            https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences
          </a>
        </div>
        <div className={classes.text}>
          <strong>5.3.7 Android:</strong>
          <br></br>
          <br></br>
          <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en">
            https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en
          </a>{' '}
          (Please refer to your device’s documentation for manufacturers’ own
          browsers)
        </div>
      </div>
      <div className={classes.textHeading}>
        6) Changes to this Cookie Policy
      </div>
      <div className={classes.text}>
        <strong>6.1</strong> We may alter this Cookie Policy at any time. Any
        such changes will become binding on you on your first use of Our Site
        after the changes have been made. You are therefore advised to check
        this page from time to time.{' '}
      </div>
      <div className={classes.text}>
        <strong>6.2</strong> In the event of any conflict between the current
        version of this Cookie Policy and any previous version(s), the
        provisions current and in effect shall prevail unless it is expressly
        stated otherwise.{' '}
      </div>
      <div className={classes.textHeading}>7) Further Information</div>
      <div className={classNames(classes.text, classes.marginBottom)}>
        <strong>7.1</strong> If you would like to know more about how We use
        Cookies, please contact us.{' '}
      </div>
    </div>
  );
}

export default CookiePolicy;
