/* tslint:disable */
/* eslint-disable */
/* @relayHash 3fa945b4e6489986126b2d64c01b9b27 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_AppHolder_QueryVariables = {};
export type App_AppHolder_QueryResponse = {
    readonly categories: {
        readonly " $fragmentRefs": FragmentRefs<"AppHolder_categories">;
    } | null;
    readonly user: {
        readonly " $fragmentRefs": FragmentRefs<"AppHolder_user">;
    } | null;
};
export type App_AppHolder_Query = {
    readonly response: App_AppHolder_QueryResponse;
    readonly variables: App_AppHolder_QueryVariables;
};



/*
query App_AppHolder_Query {
  categories {
    ...AppHolder_categories
  }
  user(noError: true) {
    ...AppHolder_user
  }
}

fragment AppHolder_categories on CategoryPage {
  edges {
    uuid
    name
    description
    color
    classroomOnly
  }
  pageInfo {
    total
  }
}

fragment AppHolder_user on User {
  firstName
  lastName
  type
  profileImageUrl
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "Literal",
            "name": "noError",
            "value": true
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_AppHolder_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "categories",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CategoryPage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "AppHolder_categories",
                            "args": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": "user(noError:true)",
                    "args": (v0 /*: any*/),
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "AppHolder_user",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_AppHolder_Query",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "categories",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CategoryPage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Category",
                            "plural": true,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "uuid",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "name",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "description",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "color",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "classroomOnly",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "pageInfo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "total",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "user",
                    "storageKey": "user(noError:true)",
                    "args": (v0 /*: any*/),
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "firstName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "lastName",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "type",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "profileImageUrl",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_AppHolder_Query",
            "id": null,
            "text": "query App_AppHolder_Query {\n  categories {\n    ...AppHolder_categories\n  }\n  user(noError: true) {\n    ...AppHolder_user\n  }\n}\n\nfragment AppHolder_categories on CategoryPage {\n  edges {\n    uuid\n    name\n    description\n    color\n    classroomOnly\n  }\n  pageInfo {\n    total\n  }\n}\n\nfragment AppHolder_user on User {\n  firstName\n  lastName\n  type\n  profileImageUrl\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'e083ac2785d769f03498d5526277d1e7';
export default node;
