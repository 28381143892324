import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import { Theme } from 'helpers/theme';
import CoreInput from 'sharedComponents/core/Input/CoreInput';
import Button from 'sharedComponents/core/Input/Button';
import Recaptcha from 'components/Recaptcha';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '65px',
    borderRadius: '14px',
    backgroundColor: theme.colors.primaryWhite,
    boxShadow: '0 1px 7px 3px rgba(0,0,0,0.11)',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 500px)': {
      flexDirection: 'column'
    },
  },
  header: {
    fontSize: theme.fontSizes.extraLargeHeading,
    fontWeight: '900',
    marginBottom: '15px',
  },
  subtitle: {
    fontSize: theme.fontSizes.extraLarge,
    color: theme.colors.textGrey,
    marginBottom: '30px',
    maxWidth: '288px',
  },
  blue: {
    cursor: 'pointer',
    color: theme.colors.navyBlue,
  },
  border: {
    width: '60px',
    borderBottom: ['3px', 'solid', theme.colors.navyBlue],
    marginBottom: '45px',
  },
  input: {
    fontSize: theme.fontSizes.default,
    padding: '11px',
    border: ['1px', 'solid', theme.colors.borderGrey],
    borderRadius: '6px',
    marginBottom: '30px',
    width: '100%'
  },
  marginLeft: {
    marginLeft: '27px',
    '@media (max-width: 500px)': {
      marginLeft: 0,
    },
  },
  last: {
    resize: 'none',
    justifyContent: 'flex-start',
  },
  button: {
    height: '52px',
    width: '163px',
    fontSize: theme.fontSizes.extraLarge,
    fontWeight: '800',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.43)',
    '@media (max-width: 500px)': {
      marginTop: theme.spacing(2)
    },
  },
  modal: {
    position: 'fixed', 
    zIndex: 1,
    paddingTop: '300px',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.4)' 
  },
  modalContent: {
    backgroundColor: theme.colors.primaryWhite,
    margin: 'auto',
    padding: theme.spacing(2),
    border: '1px solid #888',
    width: '60%',
    borderRadius: 10
  },
  fullWidth: {
    maxWidth: '100%'
  }
}));

export type ContactDetails = {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phone: string;
  extra: string;
};

type Props = {
  contactDetails: ContactDetails;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  modalHeader: string;
  modalText: string;
  onHelp: () => void;
  onSubmit: (e: any, details: ContactDetails, token: string) => void;
  className?: string;
};

function ContactPage({ contactDetails, showModal, setShowModal, modalHeader, modalText, onHelp, onSubmit, className }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  // Form Data
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [extra, setExtra] = React.useState('');
  const [recaptchaToken, setRecaptchaToken] = React.useState('');

  React.useEffect(() => {
    contactDetails = {
      firstName,
      lastName,
      companyName,
      email,
      phone,
      extra,
    };
  });

  const handleSubmit = (e: any) => {
    onSubmit(e, contactDetails, recaptchaToken);
  }

  return (
    <form className={classNames(classes.root, className)} onSubmit={handleSubmit}>
      <div className={classes.header}>Get in touch, we would love to hear from you!</div>
      <div className={classes.subtitle}>Contact our team below</div>
      <div className={classes.border} />
      <div className={classes.row}>
        <CoreInput
          placeholder="First Name"
          type="text"
          onChange={(text: string) => setFirstName(text)}
          value={firstName}
          className={classes.input}
        />

        <CoreInput
          placeholder="Last Name"
          type="text"
          onChange={(text: string) => setLastName(text)}
          value={lastName}
          className={classNames(classes.input, classes.marginLeft)}
        />
      </div>

      <CoreInput
        placeholder="Company Name"
        type="text"
        onChange={(text: string) => setCompanyName(text)}
        value={companyName}
        className={classes.input}
      />

      <CoreInput
        placeholder="Email Address"
        type="text"
        onChange={(text: string) => setEmail(text)}
        value={email}
        className={classes.input}
      />

      <CoreInput
        placeholder="Phone number"
        type="tel"
        onChange={(text: string) => setPhone(text)}
        value={phone}
        className={classes.input}
      />

      <textarea
        placeholder="Go into as much detail as you like, we're all ears!"
        rows={8}
        onChange={(event: any) => setExtra(event.target.value)}
        value={extra}
        className={classNames(classes.input, classes.last)}
      />
      <div className={classes.row}>
        <Recaptcha
          onChange={setRecaptchaToken}
        />
        <Button archetype="submit" className={classes.button}>
          Submit
        </Button>
      </div>
      {showModal && 
        <div className={classes.modal}>
          <div className={classes.modalContent}>
            <div className={classes.header}>
              {modalHeader}
            </div>
            <div className={classNames(classes.subtitle, classes.fullWidth)}>
              {modalText}
            </div>
            <Button archetype="submit" className={classes.button} onClick={() => setShowModal(false)}>
              Close
            </Button>
          </div>
        </div>
      }
    </form>
  );
}

export default ContactPage;
