/* tslint:disable */
/* eslint-disable */
/* @relayHash 296428fd6989b6f33420efe7e9d4a68c */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_Home_QueryVariables = {};
export type App_Home_QueryResponse = {
    readonly popularCourses: ReadonlyArray<{
        readonly " $fragmentRefs": FragmentRefs<"Home_popularCourses">;
    }> | null;
};
export type App_Home_Query = {
    readonly response: App_Home_QueryResponse;
    readonly variables: App_Home_QueryVariables;
};



/*
query App_Home_Query {
  popularCourses {
    __typename
    ...Home_popularCourses
  }
}

fragment Home_popularCourses on Course {
  ident: id
  name
  price
  excerpt
  introduction
  bannerImageURL
  category {
    name
    color
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_Home_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "popularCourses",
                    "storageKey": null,
                    "args": null,
                    "concreteType": null,
                    "plural": true,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "Home_popularCourses",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_Home_Query",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "popularCourses",
                    "storageKey": null,
                    "args": null,
                    "concreteType": null,
                    "plural": true,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "__typename",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": "ident",
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        (v0 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "price",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "excerpt",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "introduction",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "bannerImageURL",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "category",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Category",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "color",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_Home_Query",
            "id": null,
            "text": "query App_Home_Query {\n  popularCourses {\n    __typename\n    ...Home_popularCourses\n  }\n}\n\nfragment Home_popularCourses on Course {\n  ident: id\n  name\n  price\n  excerpt\n  introduction\n  bannerImageURL\n  category {\n    name\n    color\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'e40686fb6f5767751c1343dcc07f3682';
export default node;
