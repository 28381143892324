import * as React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { Theme } from 'helpers/theme';
import PageHeader from 'components/core/PageHeader';
import FourPanel, { Panel } from 'components/core/FourPanel';
import PeopleCurve from 'components/core/Curve/PeopleCurve';
import VideoPlayer from 'components/core/VideoPlayer';
import BrandCard, { Author } from 'components/core/Cards/BrandCard';
import PageMargin from 'components/core/PageMargin';
import Curve from 'components/core/Curve';
import Card from 'sharedComponents/core/Cards/Card';
import Icon from 'sharedComponents/core/Icon';

const useStyles = createUseStyles((theme: Theme) => ({
  consultancyRoot: {
    width: '100%',
    backgroundColor: theme.colors.primaryWhite,
  },
  textCentered: {
    alignItems: 'flex-start',
  },
  heading: {
    fontSize: theme.fontSizes.heading,
    color: theme.colors.primaryBlack,
    fontWeight: 800,
    margin: '50px 0px',
    textAlign: 'center',
  },
  subheading: {
    fontSize: theme.fontSizes.heading,
    color: theme.colors.primaryBlack,
    fontWeight: 800,
    margin: '75px 0 25px 0',
    textAlign: 'left',
  },
  text: {
    display: 'flex',
    fontSize: theme.fontSizes.tinyHeading,
    color: '#34373A',
    fontWeight: 400,
    textAlign: 'left',
    marginBottom: '15px',
  },
  greyBackground: {
    backgroundColor: theme.colors.backgroundGrey,
    paddingBottom: '100px',
    '@media (min-width: 600px)': {
      marginTop: '125px',
    },
  },
  card: {
    flex: 1,
    margin: '15px',
    maxWidth: '400px',
    '@media (max-width: 1150px)': {
      minWidth: '40%',
      margin: '5px',
    },
    '@media (max-width: 500px)': {
      alignItems: 'center'
    },
  },
  margin: {
    margin: '75px 0',
  },
  topGrid: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gridTemplateRows: '1fr',
    '@media (max-width: 900px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr',
      justifyItems: 'center',
    },
  },
  bottomGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '1fr',
    '@media (max-width: 900px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr 1fr',
    },
  },
  bullet: {
    minHeight: '10px',
    minWidth: '10px',
    maxHeight: '10px',
    maxWidth: '10px',
    borderRadius: '10px',
    margin: '7.5px 10px 0 0',
    backgroundColor: theme.colors.textBlue,
  },
  videoReplacement: {
    '@media (min-width: 800px)': {
      display: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '50px',
    backgroundColor: theme.colors.navyBlue,
    color: '#FFFFFF',
    fontSize: 16,
    maxWidth: 350,
    fontWeight: 600,
    whiteSpace: 'pre-wrap',
  },
  //  Thumbnail styles
  video: {
    '@media (max-width: 800px)': {
      display: 'none',
    },
    flex: 2,
    margin: '15px',
    alignItems: 'flex-end',
    boxShadow: '4px 2px 10px -2px rgba(0,0,0,0.06)',
    '@media (max-width: 1100px)': {
      width: '70vw',
      margin: '15px',
      boxShadow: 'none',
    },
  },
  thumbnailRoot: {
    alignSelf: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    '@media (max-width: 1100px)': {
      width: '70vw',
    },
    '@media (max-width: 500px)': {
      height: 0,
      width: 0,
      opacity: 0,
    },
  },
  playCircle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '76px',
    height: '76px',
    borderRadius: '76px',
    backgroundColor: 'white',
    opacity: 0.8,
  },
  playTriangle: {
    width: 0,
    height: 0,
    marginLeft: 6,
    borderTop: '13.5px solid transparent',
    borderBottom: '13.5px solid transparent',
    borderLeft: '27px solid #0E66E0',
  },
}));

const defaultIconPanels: Panel[] = [
  {
    title: 'Initial assessment',
    desciption:
      'We carry out an initial tailored assessment of your needs and current compliance to ascertain any issues that need to be addressed.',
    iconName: 'AnnualReport',
  },
  {
    title: 'Bespoke plan of actions',
    desciption:
      'Once we have analysed the information from the initial assessment, we design a bespoke plan of action to address the relevant issues taking into account their complexity and severity as well as costs of implementation versus the benefits.',
    iconName: 'Cost',
  },
  {
    title: 'Implementation',
    desciption:
      'Our team will help you implement the action plan. Once this is in place, if there are any unforeseen consequences we will support you in any necessary adjustments and coaching.',
    iconName: 'Help',
  },
  {
    title: 'Continually evolving processes',
    desciption:
      'Once a robust system is in place, we offer ongoing auditing and reporting services in line with regulatory requirement.',
    iconName: 'Process',
  },
];

const defaultAuthor: Author = {
  name: 'Martin Corr',
  title: 'Director Sound Moves (UK) Limited',
  quote:
    'TTC have been super responsive when we most needed it. Their team are not only knowledgeable and experienced but fun to work with. Their new system is second to none and we are delighted to have been an early customer.',
};

type Props = {};

function Consultancy(props: Props) {
  const classes = useStyles();
  return (
    <div className={classes.consultancyRoot}>
      <PageHeader
        title="CONSULTANCY"
        description="Take advantage of our full range of consultancy services"
      />
      <PageMargin>
        <div className={classes.heading}>How we work</div>
        <FourPanel panels={defaultIconPanels} />
      </PageMargin>
      <PageMargin centererStyle={classes.greyBackground}>
        <div className={classNames(classes.heading, classes.margin)}>
          Don't just take our word for it
        </div>
        <div className={classes.topGrid}>
          <VideoPlayer
            width={853}
            className={classes.video}
            image
            source={require('assets/Puzzle.png')}
            thumbnail={
              <div className={classes.thumbnailRoot}>
                <Curve
                  width={710}
                  logo="Dhl_Black"
                  description={`“The Team at TTC understand the urgency and sometimes complexity of a large business. Their system is fantastic to manage large number of staff and the records are maintained excellently, allowing the management to rely on the data. The team are super friendly and their customer service world class!“\n\nDaniel Adams\nOperations and Aviation Security Manager\nDHL`}
                  blue
                />
              </div>
            }
          />
          <Card className={classes.videoReplacement} padding="large">
            {`“The Team at TTC understand the urgency and sometimes complexity of a large business. Their system is fantastic to manage large number of staff and the records are maintained excellently, allowing the management to rely on the data. The team are super friendly and their customer service world class!“\n\nDaniel Adams\nOperations and Aviation Security Manager\nDHL`}
            <Icon
                name="Dhl_Black"
                style={{
                  width: '200px',
                  height: '60px',
                  marginTop: 25,
                  alignSelf: 'center'
                }}
              />
          </Card>
          <BrandCard
            className={classes.card}
            link="/"
            author={defaultAuthor}
            linkText=""
            logoURL={require('assets/soundmoves.jpeg')}
          />
        </div>
        <div className={classes.bottomGrid}>
          {/* <BrandCard
            className={classes.card}
            logoURL={require('assets/purple-heathrow-logo.png')}
            link="/"
            text="TTC Lorem ipsum dolor sit amet, consectetur adipiscing elitse oddo eiusmod tempor incididunt ut labore Lorem ipsum dolor sit amet, consecte"
          />
          <BrandCard
            className={classes.card}
            logoURL={require('assets/ups-logo.svg')}
            link="/"
            text="TTC Lorem ipsum dolor sit amet, consectetur adipiscing elitse oddo eiusmod tempor incididunt ut labore Lorem ipsum dolor sit amet, consecte"
          />
          <BrandCard
            className={classes.card}
            logoURL={require('assets/nippon-express.png')}
            link="/"
            text="TTC Lorem ipsum dolor sit amet, consectetur adipiscing elitse oddo eiusmod tempor incididunt ut labore Lorem ipsum dolor sit amet, consecte"
          /> */}
        </div>
      </PageMargin>
      <PageMargin centeredStyle={classes.textCentered}>
        <div className={classes.subheading}>DGSA Services</div>
        <div className={classes.text}>
          TTC offers a full dangerous goods safety adviser service. This
          includes an audit, telephone and email support as well as completing
          the annual report, which is a legal requirement.
          <br />
          <br />
          We also offer a DGSA support service for those companies that need
          support for their in-house DGSA or who do not require the full DGSA
          service. We offer competitive rates and work with you to ensure you
          are compliant whilst also helping you to save money with efficient and
          effective processes.
        </div>
        <div className={classes.subheading}>
          Dangerous Goods Consultancy Services
        </div>
        <div className={classes.text}>
          We help you to ensure that your dangerous goods processes operate in
          the most cost-effective way as well as being fully compliant with all
          the relevant dangerous goods transport regulations.
          <br />
          <br />
          We can undertake a compliance audit of your processes and offer honest
          and cost-effective solutions, if and when these are required.
          <br />
          <br />
          We are happy to discuss any consultancy services you may need such as
          producing standard operating procedures or helping companies to
          navigate the maze of requirements for the transport of dangerous
          goods.
        </div>
        <div className={classes.subheading}>
          Aviation Security Consultancy Services
        </div>
        <div className={classes.text}>
          Whether you are a new regulated agent, known consignor, regulated
          supplier or known supplier, we can run a full assessment of current
          aviation security compliance.
        </div>
        <div className={classes.subheading}>We typically deal with:</div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Physical security of your premises
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Access control, including specially protected areas, CCTV, alarms, etc
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Procedure for receiving and despatching cargo
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Cost effective screening equipment selection specific to your needs
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Screening protocols and procedures (including completion of KCCSC and
          RA CSDs)
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Procedure for the protection and secure transportation of cargo and
          mail
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          High risk cargo and mail
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Recruitment, including interview, application form, referencing,
          criminal record checks and retention of records
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Training - ensuring staff are trained to the appropriate level
          depending on their job function
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Incident and bomb warning protocols, including reporting, escalation
          procedures and dealing with the incident (contingency planning)
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          SeMS
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Risk assessor
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Introduction of the Quality Assurance Form
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          The role of the security manager, we offer one to one coaching for new
          SeMs as well as those wishing to refresh or get up to date with the
          requirements
        </div>
        <div className={classes.subheading}>HR Consultancy Services</div>
        <div className={classes.text}>
          Do you find the recruitment process challenging? We can help you! Our
          experts in the area have vast experience in navigating the plethora of
          regulatory requirements. Further, we have up to date forms and
          understanding of what is considered best practice. We can work
          alongside your HR team or supporting your security manager, whether in
          a crisis or an ongoing requirement.
        </div>
        <div className={classes.subheading}>We typically deal with:</div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Identify the staff needed
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Define job descriptions and post on various leading job boards
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Evaluate all CVs, shortlisting suitable candidates
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Arranging, conducting and concluding interviews
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Completion of application forms
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Verifying personal documentation (passports/visas/national
          insurance/proof of address)
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Criminal record checks (DBS)
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          5-year referencing, including any necessary overseas checks
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Offer letters, contracts of employment and employee handbooks
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Training, ensuring staff are trained to the appropriate level
          depending on their job function
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Policies and procedures
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Continuing professional development
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Appraisals, supervisions, disciplinary and grievances
        </div>
        <div className={classes.text}>
          <span className={classes.bullet} />
          Counter terrorism checks and airside pass applications
        </div>
      </PageMargin>
      <PeopleCurve />
    </div>
  );
}

export default Consultancy;
