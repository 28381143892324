import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import { Theme } from 'helpers/theme';
import Icon from 'sharedComponents/core/Icon';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  text: {
    fontSize: '15px',
    fontWeight: '500',
    color: theme.colors.secondaryBlack,
    marginLeft: '25px',
  },
  setSize: {
    width: 13,
    height: 13,
  },
}));

type Props = {
  text: string;
  size?: number;
  className?: string;
};

function TickBullet({ text, size = 13, className }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.setSize}>
        <Icon name="Tick" size={size} />
      </div>
      <div className={classes.text}>{text}</div>
    </div>
  );
}

export default TickBullet;
