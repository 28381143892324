/* tslint:disable */
/* eslint-disable */
/* @relayHash f563ce5ea2b2fa97070c4193ab1f0ac2 */

import { ConcreteRequest } from "relay-runtime";
export type Page = {
    offset?: number | null;
    limit?: number | null;
};
export type OrderBy = {
    ascending?: boolean | null;
    field: string;
};
export type App_ArticleLandingPage_QueryVariables = {
    page?: Page | null;
    orderBy?: OrderBy | null;
};
export type App_ArticleLandingPage_QueryResponse = {
    readonly blogs: {
        readonly edges: ReadonlyArray<{
            readonly uuid: string;
            readonly title: string;
            readonly createdAt: string;
            readonly headerImageURL: string | null;
            readonly category: {
                readonly name: string;
                readonly color: string;
            };
        } | null> | null;
    } | null;
};
export type App_ArticleLandingPage_Query = {
    readonly response: App_ArticleLandingPage_QueryResponse;
    readonly variables: App_ArticleLandingPage_QueryVariables;
};



/*
query App_ArticleLandingPage_Query(
  $page: Page
  $orderBy: OrderBy
) {
  blogs(page: $page, orderBy: $orderBy) {
    edges {
      uuid
      title
      createdAt
      headerImageURL
      category {
        name
        color
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "page",
            "type": "Page",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "orderBy",
            "type": "OrderBy",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "blogs",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "orderBy",
                    "variableName": "orderBy"
                },
                {
                    "kind": "Variable",
                    "name": "page",
                    "variableName": "page"
                }
            ],
            "concreteType": "BlogPage",
            "plural": false,
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Blog",
                    "plural": true,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "uuid",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "createdAt",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "headerImageURL",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "category",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Category",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "name",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "color",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_ArticleLandingPage_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "App_ArticleLandingPage_Query",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "App_ArticleLandingPage_Query",
            "id": null,
            "text": "query App_ArticleLandingPage_Query(\n  $page: Page\n  $orderBy: OrderBy\n) {\n  blogs(page: $page, orderBy: $orderBy) {\n    edges {\n      uuid\n      title\n      createdAt\n      headerImageURL\n      category {\n        name\n        color\n      }\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '9f237354903c300880868a60255cced4';
export default node;
