/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExploreCourses_popularCourses = ReadonlyArray<{
    readonly ident: number;
    readonly name: string;
    readonly price: number;
    readonly excerpt: string | null;
    readonly introduction: string | null;
    readonly bannerImageURL: string | null;
    readonly category: {
        readonly name: string;
        readonly color: string;
    } | null;
    readonly " $refType": "ExploreCourses_popularCourses";
}>;
export type ExploreCourses_popularCourses$data = ExploreCourses_popularCourses;
export type ExploreCourses_popularCourses$key = ReadonlyArray<{
    readonly " $data"?: ExploreCourses_popularCourses$data;
    readonly " $fragmentRefs": FragmentRefs<"ExploreCourses_popularCourses">;
}>;



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "ExploreCourses_popularCourses",
        "type": "Course",
        "metadata": {
            "plural": true
        },
        "argumentDefinitions": [],
        "selections": [
            {
                "kind": "ScalarField",
                "alias": "ident",
                "name": "id",
                "args": null,
                "storageKey": null
            },
            (v0 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "price",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "excerpt",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "introduction",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "bannerImageURL",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "category",
                "storageKey": null,
                "args": null,
                "concreteType": "Category",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "color",
                        "args": null,
                        "storageKey": null
                    }
                ]
            }
        ]
    } as any;
})();
(node as any).hash = 'c487974b4bc6ad7caae559a6b0c94cf0';
export default node;
