import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import ArticleHeader from 'components/Overview/Article/ArticleHeader';
import GenreHeader from 'components/Overview/Article/GenreHeader';
import PageMargin from 'components/core/PageMargin';
import { useRouter } from 'found';
import articles, { genres } from './articles';
import { buildURL } from 'react-imgix';
import Linkify from 'react-linkify';
import Spacer from 'sharedComponents/core/Spacers/Spacer';
import LinkedIn from 'components/images/LinkedIn';
import Envelope from 'components/images/Envelope';
import moment from 'moment';
import EditorText from 'components/core/EditorText';
import { Article as Blog } from 'components/Overview/Article/ArticleCard/ArticleCard';

const useStyles = createUseStyles((theme: Theme) => ({
  articleRoot: {
    width: '100%',
    backgroundColor: theme.colors.primaryWhite,
  },
  header: {
    marginTop: '60px',
  },
  text: {
    margin: '50px 0',
    zIndex: 1500,
    backgroundColor: 'white'
    // fontWeight: 100,
    // wordBreak: 'break-word',
  },
  centerer: {
    display: 'flex',
    justifyContent: 'center',
  },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '1000px',
    '@media (max-width: 800px)': {
      alignItems: 'center',
      width: '90%'
    },
  },
  splitter: {
    display: 'flex',
  },
  socialItems: {
    margin: '40px 0',
  },
  bubble: {
    width: 50,
    height: 50,
    background: '#f1f3f5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    cursor: 'pointer',
    transition: '0.3s all',
    '&:hover': {
      boxShadow: '0px 4px 4px #00000033',
    },
  },
}));

type Props = {
  blog: Blog;
};

function Article({ blog: article }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { router } = useRouter();
  const selectItem = (item: any) => {
    router.push('/articles');
  };
  console.log(article);
  return (
    <div className={classes.articleRoot}>
      <GenreHeader
        genres={[article.category.name]}
        selected={article.category.name}
        setSelected={selectItem}
      />
      <ArticleHeader
        className={classes.header}
        title={article.title}
        date={moment(article.createdAt).format('MMMM YYYY')}
        genre={article.category.name}
        image={article.headerImageURL}
        author={{
          name: 'TTC Admin',
          // url: require('assets/SampleImage_ClassroomCoursesDetail_Feat.png'),
        }}
      />
      <PageMargin>
        <div className={classes.centerer}>
          <div className={classes.centered}>
            <div className={classes.splitter}>
              <div className={classes.socialItems}>
                <div
                  className={classes.bubble}
                  onClick={() => {
                    window.open(
                      'https://www.linkedin.com/sharing/share-offsite/?url=' +
                        window.location.href,
                    );
                  }}
                >
                  <LinkedIn />
                </div>
                <Spacer vertical spacing={1} />
                <a
                  className={classes.bubble}
                  href={
                    'mailto:?body=Take a look at this TTC article: ' +
                    window.location.href
                  }
                >
                  <Envelope />
                </a>
              </div>
              <Spacer horizontal spacing={5} />
              <EditorText text={article.body ?? ''} className={classes.text}/>
            </div>
          </div>
        </div>
      </PageMargin>
    </div>
  );
}

export default Article;
