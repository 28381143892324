import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import { Theme } from 'helpers/theme';
import { buildURL } from 'react-imgix';
import moment from 'moment';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '3px',
    backgroundColor: theme.colors.backgroundGrey,
    transition: 'all 0.2s',
    '&:hover': {
      boxShadow: '4px 9px 39px rgba(0,0,0,0.19)',
      backgroundColor: theme.colors.primaryWhite,
    },
  },
  image: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '241px',
    width: '100%',
    borderRadius: '3px 3px 0 0',
    backgroundSize: 'cover',
  },
  type: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20px',
    height: '33px',
    fontSize: theme.fontSizes.xSmall,
    color: theme.colors.primaryWhite,
    fontWeight: '800',
    borderRadius: '3px 0 10px 0',
  },
  date: {
    fontSize: theme.fontSizes.xSmall,
    fontWeight: '800',
    color: theme.colors.textGrey,
    margin: '15px 0',
  },
  description: {
    textAlign: 'center',
    fontSize: theme.fontSizes.large,
    margin: '0 25px 20px 25px',
  },
}));

export type ArticleDetails = {
  type: number;
  imageURL: string;
  date: string;
  description: string;
};

export type Article = {
  uuid: string;
  title: string;
  headerImageURL: string | null;
  category: {
    name: string;
    color: string;
  };
  createdAt: string;
  body?: string;
}

type Props = {
  article: Article;
  onClick: (uuid: string) => void;
  className?: string;
};

function ArticleCard({ article, onClick, className }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classNames(classes.root, className)} onClick={() => onClick(article.uuid)}>
      <div
        className={classes.image}
        style={ article.headerImageURL ? {
          backgroundImage: `url(${buildURL(article.headerImageURL, {
            w: 660,
            // h: 241,
            q: 0.2,
          })})`,
        }: {}}
      >
        <div className={classes.type} style={{ backgroundColor: article.category.color }}>{article.category.name}</div>
      </div>
      <div className={classes.date}>{moment(article.createdAt).format('MMMM YYYY')}</div>
      <div className={classes.description}>{article.title}</div>
    </div>
  );
}

export default ArticleCard;
