import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import { useRouter } from 'found';
import { Theme } from 'helpers/theme';
import ArticleHeader from 'components/Overview/Article/ArticleHeader';
import ArticleCard, { Article } from 'components/Overview/Article/ArticleCard/ArticleCard';
import Button from 'sharedComponents/core/Input/Button';
import GenreHeader from 'components/Overview/Article/GenreHeader';
import PageMargin from 'components/core/PageMargin';
import articles, { genres } from './articles';
import moment from 'moment';

const useStyles = createUseStyles((theme: Theme) => ({
  landingRoot: {
    width: '100%',
    backgroundColor: theme.colors.primaryWhite,
  },
  header: {
    marginTop: '60px',
  },
  articleGrid: {
    margin: '30px',
    display: 'grid',
    gridTemplateColumns: '400px 400px 400px',
    gridGap: '30px',
    '@media (min-width: 900px) and (max-width: 1200px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media (max-width: 900px)': {
      gridTemplateColumns: '400px',
    },
  },
  button: {
    height: '52px',
    width: '182px',
    margin: '30px 0 60px 0',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.09)',
    fontSize: theme.fontSizes.large,
    fontWeight: '800',
  },
}));

type Props = {
  articles: Article[];
};

function ArticleLandingPage({ articles }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { router, match } = useRouter();

  const headerArticle = articles[0];
  return (
    <div className={classes.landingRoot}>
      <GenreHeader
        genres={[headerArticle?.category.name ?? '']}
        selected={headerArticle?.category.name ?? ''}
        setSelected={() => {}}
      />
      <ArticleHeader
        className={classes.header}
        title={headerArticle?.title ?? ''}
        date={moment(headerArticle?.createdAt).format('MMMM YYYY')}
        genre={headerArticle?.category.name}
        featured="Featured Article"
        image={headerArticle?.headerImageURL}
        onClick={() =>
          router.push(
            `/article/${headerArticle?.uuid}`,
          )
        }
      />
      <PageMargin>
        <div className={classes.articleGrid}>
          {articles.map((article) => (
            article !== null &&
            <ArticleCard
              key={article.uuid}
              article={article}
              onClick={() =>
                router.push(
                  `/article/${article.uuid}`,
                )
              }
            />
          ))}
        </div>
        {/* <Button className={classes.button} small>
          More Articles
        </Button> */}
      </PageMargin>
    </div>
  );
}

export default ArticleLandingPage;