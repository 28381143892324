/* tslint:disable */
/* eslint-disable */
/* @relayHash 005a8f94e91d00cb9ea28bfa74672984 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_ExploreCourses_QueryVariables = {};
export type App_ExploreCourses_QueryResponse = {
    readonly popularCourses: ReadonlyArray<{
        readonly " $fragmentRefs": FragmentRefs<"ExploreCourses_popularCourses">;
    }> | null;
    readonly categories: {
        readonly " $fragmentRefs": FragmentRefs<"ExploreCourses_categories">;
    } | null;
};
export type App_ExploreCourses_Query = {
    readonly response: App_ExploreCourses_QueryResponse;
    readonly variables: App_ExploreCourses_QueryVariables;
};



/*
query App_ExploreCourses_Query {
  popularCourses {
    __typename
    ...ExploreCourses_popularCourses
  }
  categories {
    ...ExploreCourses_categories
  }
}

fragment ExploreCourses_categories on CategoryPage {
  edges {
    uuid
    name
    description
    color
    classroomOnly
    imageURL
  }
  pageInfo {
    total
  }
}

fragment ExploreCourses_popularCourses on Course {
  ident: id
  name
  price
  excerpt
  introduction
  bannerImageURL
  category {
    name
    color
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v1 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "color",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_ExploreCourses_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "popularCourses",
                    "storageKey": null,
                    "args": null,
                    "concreteType": null,
                    "plural": true,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "ExploreCourses_popularCourses",
                            "args": null
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "categories",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CategoryPage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "FragmentSpread",
                            "name": "ExploreCourses_categories",
                            "args": null
                        }
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "App_ExploreCourses_Query",
            "argumentDefinitions": [],
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "popularCourses",
                    "storageKey": null,
                    "args": null,
                    "concreteType": null,
                    "plural": true,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "__typename",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": "ident",
                            "name": "id",
                            "args": null,
                            "storageKey": null
                        },
                        (v0 /*: any*/),
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "price",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "excerpt",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "introduction",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "bannerImageURL",
                            "args": null,
                            "storageKey": null
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "category",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Category",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                (v1 /*: any*/)
                            ]
                        }
                    ]
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "categories",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "CategoryPage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Category",
                            "plural": true,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "uuid",
                                    "args": null,
                                    "storageKey": null
                                },
                                (v0 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "description",
                                    "args": null,
                                    "storageKey": null
                                },
                                (v1 /*: any*/),
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "classroomOnly",
                                    "args": null,
                                    "storageKey": null
                                },
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "imageURL",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        },
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "pageInfo",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "total",
                                    "args": null,
                                    "storageKey": null
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "App_ExploreCourses_Query",
            "id": null,
            "text": "query App_ExploreCourses_Query {\n  popularCourses {\n    __typename\n    ...ExploreCourses_popularCourses\n  }\n  categories {\n    ...ExploreCourses_categories\n  }\n}\n\nfragment ExploreCourses_categories on CategoryPage {\n  edges {\n    uuid\n    name\n    description\n    color\n    classroomOnly\n    imageURL\n  }\n  pageInfo {\n    total\n  }\n}\n\nfragment ExploreCourses_popularCourses on Course {\n  ident: id\n  name\n  price\n  excerpt\n  introduction\n  bannerImageURL\n  category {\n    name\n    color\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '1a7a479ef944c7707f12fb3a04ed1bb5';
export default node;
