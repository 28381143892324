/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Courses_secondaryCategories = {
    readonly edges: ReadonlyArray<{
        readonly uuid: string;
        readonly name: string;
    } | null> | null;
    readonly " $refType": "Courses_secondaryCategories";
};
export type Courses_secondaryCategories$data = Courses_secondaryCategories;
export type Courses_secondaryCategories$key = {
    readonly " $data"?: Courses_secondaryCategories$data;
    readonly " $fragmentRefs": FragmentRefs<"Courses_secondaryCategories">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "Courses_secondaryCategories",
    "type": "SecondaryCategoryPage",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "SecondaryCategory",
            "plural": true,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "uuid",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                }
            ]
        }
    ]
} as any);
(node as any).hash = 'f6fb90af95b6e5ea464fc9fe8ae841b8';
export default node;
