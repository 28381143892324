/* tslint:disable */
/* eslint-disable */
/* @relayHash e8e00e5bfd75448ac88dadbb91b123f4 */

import { ConcreteRequest } from "relay-runtime";
export type CoursesQueryVariables = {
    categoryUUID?: string | null;
    role?: string | null;
    timeframe?: string | null;
    secondaryCategoryUUID?: string | null;
    offset: number;
};
export type CoursesQueryResponse = {
    readonly courses: {
        readonly edges: ReadonlyArray<{
            readonly ident: number;
            readonly name: string;
            readonly price: number;
            readonly excerpt: string | null;
            readonly introduction: string | null;
            readonly bannerImageURL: string | null;
        } | null> | null;
        readonly pageInfo: {
            readonly total: number;
            readonly offset: number;
            readonly limit: number;
            readonly given: number;
        } | null;
    } | null;
};
export type CoursesQuery = {
    readonly response: CoursesQueryResponse;
    readonly variables: CoursesQueryVariables;
};



/*
query CoursesQuery(
  $categoryUUID: UUID
  $role: String
  $timeframe: String
  $secondaryCategoryUUID: UUID
  $offset: Int!
) {
  courses(filter: {categoryUUID: $categoryUUID, role: $role, timeframe: $timeframe, secondaryCategoryUUID: $secondaryCategoryUUID}, page: {limit: 5, offset: $offset}, orderBy: {ascending: false, field: "priority"}) {
    edges {
      __typename
      ident: id
      name
      price
      excerpt
      introduction
      bannerImageURL
    }
    pageInfo {
      total
      offset
      limit
      given
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "categoryUUID",
            "type": "UUID",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "role",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "timeframe",
            "type": "String",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "secondaryCategoryUUID",
            "type": "UUID",
            "defaultValue": null
        } as any),
        ({
            "kind": "LocalArgument",
            "name": "offset",
            "type": "Int!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "ObjectValue",
            "name": "filter",
            "fields": [
                {
                    "kind": "Variable",
                    "name": "categoryUUID",
                    "variableName": "categoryUUID"
                },
                {
                    "kind": "Variable",
                    "name": "role",
                    "variableName": "role"
                },
                {
                    "kind": "Variable",
                    "name": "secondaryCategoryUUID",
                    "variableName": "secondaryCategoryUUID"
                },
                {
                    "kind": "Variable",
                    "name": "timeframe",
                    "variableName": "timeframe"
                }
            ]
        } as any),
        ({
            "kind": "Literal",
            "name": "orderBy",
            "value": {
                "ascending": false,
                "field": "priority"
            }
        } as any),
        ({
            "kind": "ObjectValue",
            "name": "page",
            "fields": [
                {
                    "kind": "Literal",
                    "name": "limit",
                    "value": 5
                },
                {
                    "kind": "Variable",
                    "name": "offset",
                    "variableName": "offset"
                }
            ]
        } as any)
    ], v2 = ({
        "kind": "ScalarField",
        "alias": "ident",
        "name": "id",
        "args": null,
        "storageKey": null
    } as any), v3 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any), v4 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "price",
        "args": null,
        "storageKey": null
    } as any), v5 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "excerpt",
        "args": null,
        "storageKey": null
    } as any), v6 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "introduction",
        "args": null,
        "storageKey": null
    } as any), v7 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "bannerImageURL",
        "args": null,
        "storageKey": null
    } as any), v8 = ({
        "kind": "LinkedField",
        "alias": null,
        "name": "pageInfo",
        "storageKey": null,
        "args": null,
        "concreteType": "PageInfo",
        "plural": false,
        "selections": [
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "total",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "offset",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "limit",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "given",
                "args": null,
                "storageKey": null
            }
        ]
    } as any);
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "CoursesQuery",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "courses",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CoursePage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": null,
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/)
                            ]
                        },
                        (v8 /*: any*/)
                    ]
                }
            ]
        },
        "operation": {
            "kind": "Operation",
            "name": "CoursesQuery",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": [
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "courses",
                    "storageKey": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CoursePage",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": null,
                            "plural": true,
                            "selections": [
                                {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "__typename",
                                    "args": null,
                                    "storageKey": null
                                },
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/)
                            ]
                        },
                        (v8 /*: any*/)
                    ]
                }
            ]
        },
        "params": {
            "operationKind": "query",
            "name": "CoursesQuery",
            "id": null,
            "text": "query CoursesQuery(\n  $categoryUUID: UUID\n  $role: String\n  $timeframe: String\n  $secondaryCategoryUUID: UUID\n  $offset: Int!\n) {\n  courses(filter: {categoryUUID: $categoryUUID, role: $role, timeframe: $timeframe, secondaryCategoryUUID: $secondaryCategoryUUID}, page: {limit: 5, offset: $offset}, orderBy: {ascending: false, field: \"priority\"}) {\n    edges {\n      __typename\n      ident: id\n      name\n      price\n      excerpt\n      introduction\n      bannerImageURL\n    }\n    pageInfo {\n      total\n      offset\n      limit\n      given\n    }\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = 'c11bd19dcc6659813803a8c051b679cc';
export default node;
