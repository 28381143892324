import * as React from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { Theme } from 'helpers/theme';
import Icon from 'sharedComponents/core/Icon';

const useStyles = createUseStyles((theme: Theme) => ({
  tabRoot: {
    position: 'absolute',
    top: '75px',
  },
  offset: {
    top: 0,
    left: 295,
    paddingLeft: '10px',
    zIndex: -1,
  },
  tabDropdown: {
    position: 'relative',
    width: '300px',
    border: ['0.5px', 'solid', theme.colors.borderGrey],
    borderRadius: '8px',
    backgroundColor: theme.colors.primaryWhite,
    paddingTop: '5px',
    paddingBottom: '5px',
    boxShadow: '0px 3px 10px #0000001f',
  },
  tabOption: {
    padding: '20px',
    transition: 'all 0.2s',
    '&:hover': {
      background: '#dcdcdc33',
    },
  },
  selectedOption: {
    opacity: 0.5,
  },
  tabTitle: {
    fontSize: theme.fontSizes.large,
    fontWeight: '500',
    marginBottom: '5px',
  },
  tabText: {
    fontSize: theme.fontSizes.small,
    fontWeight: '400',
    color: theme.colors.textGrey,
  },
  tab: {
    fontFamily: 'Muli',
    fontSize: theme.fontSizes.large,
    fontWeight: '300',
    marginRight: '30px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  title: {
    fontSize: theme.fontSizes.large,
    fontWeight: 300,
  },
}));

export interface Tab {
  id: number | string;
  title: string;
  text?: string;
  link?: string;
  options?: Tab[];
}

type OptionDropdownProps = {
  options: Tab[];
  offset?: boolean;
  onClick: (tab: Tab) => void;
};

const OptionDropdown = ({ options, offset, onClick }: OptionDropdownProps) => {
  const classes = useStyles();
  const [chosen, setChosen] = React.useState<number | string | undefined>();
  return (
    <div className={classNames(classes.tabRoot, offset && classes.offset)}>
      <div className={classNames(classes.tabDropdown)}>
        {options &&
          options.map((option) => (
            <Option
              key={option.id}
              tab={option}
              chosen={chosen === option.id}
              setChosen={setChosen}
              onClick={onClick}
            />
          ))}
      </div>
    </div>
  );
};

type OptionProps = {
  tab: Tab;
  chosen: boolean | undefined;
  setChosen: (sel: number | string | undefined) => void;
  onClick: (tab: Tab) => void;
};

const Option = ({ tab, chosen, setChosen, onClick }: OptionProps) => {
  const { id, title, text, link, options } = tab;
  const classes = useStyles();
  return (
    <div
      key={id}
      className={classes.tabOption}
      onClick={(evt) => {
        link && !options && onClick({ id, title, text, link, options });
        const set = id;
        options && setChosen(set);
      }}
    >
      <div
        className={classNames(
          classes.tabTitle,
          chosen && classes.selectedOption,
        )}
      >
        {title}
      </div>
      <div
        className={classNames(
          classes.tabText,
          chosen && classes.selectedOption,
        )}
      >
        {text}
      </div>
      {chosen && options && (
        <OptionDropdown options={options} offset onClick={onClick} />
      )}
    </div>
  );
};

type Props = {
  tab: Tab;
  selected?: Tab;
  setSelected: (tab?: Tab) => void;
  onClick: (tab: Tab) => void;
};

function TabOption({ tab, selected, setSelected, onClick }: Props) {
  const classes = useStyles();

  return (
    <div key={tab.id} className={classes.tab}>
      <div
        className={classes.title}
        onClick={() => {
          if (tab.options) {
            selected && selected.id === tab.id
              ? setSelected(undefined)
              : setSelected(tab);
          } else if (tab.link) {
            onClick(tab);
          }
        }}
      >
        {tab.title}
      </div>
      {tab.options && (
        <Icon
          name="Down_Arrow"
          size={10}
          style={{ cursor: 'pointer', marginLeft: '5px' }}
        />
      )}
      {selected && tab.id === selected.id && tab.options && (
        <OptionDropdown options={tab.options} onClick={onClick} />
      )}
    </div>
  );
}

export default TabOption;
