import MUIEditor, { MUIEditorState } from "react-mui-draft-wysiwyg";
import { convertFromRaw } from 'draft-js';
import * as React from 'react';

type Props = {
  text: string;
  className?: string;
};

function EditorText({ text, className }: Props){
  const editorConfig = {
    toolbar: { visible: false },
    draftEditor: { readOnly: true },
    editor: {
      wrapperElement: 'div',
      className,
    },
  };

  const [editorState, setEditorState] = React.useState(
    MUIEditorState.createWithContent(
      editorConfig,
      convertFromRaw(JSON.parse(text))
    )
  );

  return (
    <MUIEditor
      editorState={editorState}
      onChange={setEditorState}
      config={editorConfig}
    />
  );
}

export default EditorText;