import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { Theme } from 'helpers/theme';
import Icon from 'sharedComponents/core/Icon';

const useStyles = createUseStyles((theme: Theme) => ({
  cookieRoot: {
    maxWidth: 420,
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 2000,
    backgroundColor: theme.colors.primaryWhite,
    borderTopRightRadius: '15px',
    boxShadow: '5px 5px 10px rgba(0,0,0,0.3)',
  },
  content: {
    padding: `0 ${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(
      2,
    )}px`,
  },
  closeRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  highlighted: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderTopRightRadius: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.colors.lightBlue,
  },
  header: {
    fontSize: theme.fontSizes.tinyHeading,
    fontWeight: '600',
    color: theme.colors.primaryBlack,
    marginBottom: '15px',
  },
  body: {
    fontSize: theme.fontSizes.large,
    color: theme.colors.textGrey,
  },
  link: {
    color: theme.colors.textBlue,
    textDecorationLine: 'none',
  },
}));

type Props = {
  className?: string;
  privacyPolicyURL: string;
};

function CookiesNotification({ className, privacyPolicyURL }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    Cookies.set('consent', 'true');
    setOpen(false);
  };

  React.useEffect(() => {
    const consent = Cookies.get('consent');
    if (!consent) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <div className={classNames(classes.cookieRoot, className)}>
      <div className={classes.closeRow}>
        <div className={classes.highlighted} onClick={handleClose}>
          <Icon name="CloseHamburger" size={15} style={{ cursor: 'pointer' }} />
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.header}>Cookie Notice</div>
        <div className={classes.body}>
          We use Cookies to give you the best experience, as detailed in our{' '}
          <a className={classes.link} href={privacyPolicyURL} target="_blank">
            Privacy Policy
          </a>
          . By continuing to use this website, you consent to the use of
          cookies.
        </div>
      </div>
    </div>
  );
}

export default CookiesNotification;
