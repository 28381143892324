import * as React from 'react';
import HeaderMenu, { Tab } from 'components/Menu/HeaderMenu';
import { createUseStyles, useTheme } from 'react-jss';
import { useRouter } from 'found';
import { Theme } from 'helpers/theme';
import Footer from 'components/Menu/Footer';
import {
  delegateLogin,
  managerLogin,
  delegateSite,
  managerSite,
} from 'api/config';
import { createFragmentContainer, graphql } from 'react-relay';
import { AppHolder_categories } from './__generated__/AppHolder_categories.graphql';
import { AppHolder_user } from './__generated__/AppHolder_user.graphql';
import CookiesNotification from 'components/Misc/CookiesNotification';

const useStyles = createUseStyles((theme: Theme) => ({
  appHolder: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.colors.primaryBlack,
    paddingTop: 83,
  },
  appHolderRoot: {
    overflow: 'hidden',
    width: '100%',
    position: 'relative',
  },
}));

type Props = {
  children?: React.ReactChildren;
  categories: AppHolder_categories;
  user?: AppHolder_user;
};

const alphabeticalSortFunc = function (
  a?: { name: string } | null,
  b?: { name: string } | null,
) {
  var nameA = a?.name.toUpperCase(); // ignore upper and lowercase
  var nameB = b?.name.toUpperCase(); // ignore upper and lowercase
  if (!nameA || !nameB) {
    return 0;
  }
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

const AppHolder = ({ children, categories, user }: Props) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const { match, router } = useRouter();

  const onlineCategories = (categories?.edges ?? [])
    .filter((category) => !category?.classroomOnly, [])
    .sort(alphabeticalSortFunc);
  const classroomCategories = (categories?.edges ?? [])
    .filter((category) => category?.classroomOnly, [])
    .sort(alphabeticalSortFunc);

  const onlineCategoryOptions = onlineCategories.map((category) => ({
    id: category?.uuid ?? '',
    title: category?.name ?? '',
    text: category?.description ?? '',
    link: `/courses/${category?.uuid}`,
  }));

  const classroomCategoryOptions = classroomCategories.map((category) => ({
    id: category?.uuid ?? '',
    title: category?.name ?? '',
    text: category?.description ?? '',
    link: `/courses/${category?.uuid}`,
  }));

  const tabs: Array<Tab> = [
    {
      id: 0,
      title: 'Features',
      options: [
        {
          id: 0,
          title: 'For Companies',
          text: 'Empower your team with the most engaging compliance training',
          link: '/companyfeatures',
        },
        {
          id: 1,
          title: 'For Individuals',
          text: 'Book your next course to further your career in logistics',
          link: '/individualfeatures',
        },
      ],
    },
    {
      id: 1,
      title: 'Courses',
      options: [
        {
          id: 0,
          title: 'Online Courses',
          link: '/courses',
          text:
            'Beautifully designed animated videos to ensure the best online training experience',
          options: onlineCategoryOptions,
        },
        {
          id: 1,
          title: 'Classroom Courses',
          link: '/courses',
          text: 'Led by world-class instructors to support your learning',
          options: classroomCategoryOptions,
        },
      ],
    },
    {
      id: 2,
      title: 'Resources',
      options: [
        {
          id: 0,
          title: 'News',
          text:
            'Stay in touch with all the industry news from the team at TTC Hub',
          link: '/articles',
        },
        {
          id: 1,
          title: 'Book A Demo',
          text:
            'Looking for more information on our platform? Let us show you the ropes',
          link: '/https://calendly.com/ttc-hub',
        },
        {
          id: 2,
          title: 'About Us',
          text:
            'We are a growing team of industry experts with 50+ years of industry experience',
          link: '/aboutus',
        },
        {
          id: 3,
          title: 'Contact Us',
          text: 'Get in touch with us, we would love to hear from you!',
          link: '/contact',
        },
      ],
    },
    { id: 3, title: 'Consultancy', link: '/consultancy' },
  ];

  const selectedRoute = () => {
    const { routes } = match;
    const currentRouter = routes[routes.length - 1];
    switch (currentRouter.path) {
      case '/':
        return tabs[0];
      default:
        return tabs[0];
    }
  };

  const [selected, setSelected] = React.useState<Tab | undefined>();

  const footerColumns = [
    {
      id: 0,
      header: 'Online Courses',
      links:
        onlineCategories.map((category, index) => ({
          id: index,
          name: category?.name ?? '',
          link: '/courses/' + category?.uuid,
        })) ?? [],
    },
    {
      id: 1,
      header: 'Classroom Courses',
      links:
        classroomCategories.map((category, index) => ({
          id: index,
          name: category?.name ?? '',
          link: '/courses/' + category?.uuid,
        })) ?? [],
    },
    {
      id: 2,
      header: 'Resources',
      links: [
        {
          id: 0,
          name: 'About Us',
          link: '/aboutus',
        },
        {
          id: 1,
          name: 'Contact Us',
          link: '/contact',
        },
        {
          id: 2,
          name: 'Privacy Policy',
          link: '/policies',
        },
        { id: 3, name: 'Cookie Policy', link: '/policies/cookies' },
      ],
    },
  ];

  return (
    <div className={classes.appHolderRoot}>
      <HeaderMenu
        tabs={tabs}
        selected={selected}
        setSelected={(tab?: Tab) => setSelected(tab)}
        onClick={(link) => {
          setSelected(undefined);
          if (link.title === 'Book A Demo') {
            window.open('https://calendly.com/ttc-hub', '_blank');
          } else {
            link.link && window.scrollTo(0, 0);
            link.link && router.push(link.link);
          }
        }}
        onRegisterClick={() => {
          router.push('/register');
        }}
        onLogoClick={() => {
          router.push('/');
        }}
        user={
          user
            ? {
                firstName: user.firstName,
                lastName: user.lastName,
                profileIconURL: user.profileImageUrl ?? undefined,
              }
            : undefined
        }
        onDashboardClick={() => {
          switch (user?.type) {
            case 'delegate':
              window.location.href = delegateSite;
              break;
            case 'individual':
              window.location.href = delegateSite;
              break;
            case 'manager':
              window.location.href = managerSite;
              break;
          }
        }}
        onCheckout={() => console.log('Checkout')}
        onLoginClick={() => {
          window.location.href = delegateLogin;
        }}
      />
      <CookiesNotification privacyPolicyURL={'/policies/privacy'} />
      <div className={classes.appHolder}>{children}</div>
      <Footer columns={footerColumns} />
    </div>
  );
};

export default createFragmentContainer(AppHolder, {
  categories: graphql`
    fragment AppHolder_categories on CategoryPage {
      edges {
        uuid
        name
        description
        color
        classroomOnly
      }
      pageInfo {
        total
      }
    }
  `,
  user: graphql`
    fragment AppHolder_user on User {
      firstName
      lastName
      type
      profileImageUrl
    }
  `,
});
