import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import classNames from 'classnames';
import Spacer from 'sharedComponents/core/Spacers/Spacer';
import { Router } from 'found';
import { Course } from 'sharedComponents/Overview/CourseCard';
import PageMargin from 'components/core/PageMargin';
import PeopleCurve from 'components/core/Curve/PeopleCurve';
import PageHeader from 'components/core/PageHeader';
import CarouselWithDemo from 'components/Misc/CarouselCourse/CarouselWithDemo';
import FourPanel from 'components/core/FourPanel';
import { Panel } from 'components/core/FourPanel/FourPanel';
import BrandCard from 'components/core/Cards/BrandCard';
import { createFragmentContainer, graphql } from 'react-relay';
import { ExploreCourses_popularCourses } from './__generated__/ExploreCourses_popularCourses.graphql';
import { ExploreCourses_categories } from './__generated__/ExploreCourses_categories.graphql';
import { buildURL } from 'react-imgix';

const useStyles = createUseStyles((theme: Theme) => ({
  homeRoot: {
    width: '100%',
    background: 'white',
  },
  rowBig: {
    display: 'grid',
    width: '100%',
    justifyContent: 'center',
    '@media (min-width: 575px)': {
      gridTemplateColumns: 'repeat(auto-fit, 531px)',
    },
  },
  rowSmall: {
    display: 'grid',
    width: '100%',
    justifyContent: 'center',
    '@media (min-width: 575px)': {
      gridTemplateColumns: 'repeat(auto-fit, 400px)',
    },
  },
  greySpacer: {
    background: theme.colors.backgroundGrey,
    padding: '40px 0px 100px 0px',
  },
  heading: {
    fontSize: 32,
    color: theme.colors.primaryBlack,
    fontWeight: 800,
    padding: '80px 0px 60px 0px',
    textAlign: 'center',
  },
  subheading: {
    fontSize: theme.fontSizes.extraLarge,
    color: theme.colors.secondaryBlack,
    fontWeight: 800,
    padding: '60px 0px',
    textAlign: 'center',
  },
  buttonHolder: {
    display: 'grid',
    gridTemplateColumns: '1fr 75px 1fr',
    gridSpace: '50px',
    '@media (max-width: 575px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr 1fr',
    },
  },
  text: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: theme.fontSizes.extraLarge,
    color: theme.colors.primaryBlack,
  },
  button: {
    height: 52,
    fontSize: 18,
    fontWeight: 800,
    boxShadow: '0px 2px 9px #00000014',
    padding: '0px 36px',
  },
  card: {
    margin: '15px',
    width: 362,
    '@media (max-width: 1000px)': {
      minWidth: '40%',
      margin: '5px',
    },
  },
  thinItem: {
    width: 362,
  },
  longCard: {
    margin: '5px',
    maxWidth: 500,
    '@media (min-width: 1000px)': {
      width: '100%',
      margin: '15px',
    },
  },
  smallMargin: {
    margin: '40px 0',
  },
  marginBottom: {
    marginBottom: '80px',
  },
  longItem: {
    width: '100%',
    maxWidth: 500,
    '@media (min-width: 1000px)': {
      minWidth: 500,
    },
  },
}));

const defaultImagePanels: Panel[] = [
  {
    title: 'Register',
    desciption:
      'Whether as a company or an individual who wishes to enhance their career, firstly register with us and create an account.',
    imageURL: require('assets/StepOne.png'),
  },
  {
    title: 'Book a course',
    desciption:
      'Book members of your team, or yourself onto one of our many courses, whether this is online, classroom or hybrid.',
    imageURL: require('assets/StepTwo.png'),
  },
  {
    title: 'Course completion',
    desciption:
      'A member of your team, or you, can enjoy the experience of one of our courses furthering knowledge and helping career development.',
    imageURL: require('assets/StepThree.png'),
  },
  {
    title: 'Certification and records',
    desciption:
      'On successful completion delegates will be certified and relevant records will be logged on their account for future reference.',
    imageURL: require('assets/StepFour.png'),
  },
];

type Props = {
  router: Router;
  popularCourses: ExploreCourses_popularCourses;
  categories: ExploreCourses_categories;
};

function ExploreCourses({ router, popularCourses, categories }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const courses = popularCourses.map((course) => ({
    id: course.ident,
    type: course?.category?.name ?? '',
    colour: course?.category?.color ?? '',
    url: course.bannerImageURL ?? '',
    title: course.name,
    price: course.price,
    description: course.excerpt ?? '',
    onClick: () => {
      window.scrollTo(0, 0);
      router.push('/course/' + course.ident);
    },
    // location: 'TTC at Hilton T4',
    // modules: 16,
    // lessons: 144,
    // videoTime: 4,
  }));

  const onlineCategories = categories.edges?.filter((cat) => {
    if (cat?.classroomOnly) return false;
    return true;
  });

  const classroomCategories = categories.edges?.filter((cat) => {
    if (cat?.classroomOnly) return true;
    return false;
  });

  return (
    <div className={classes.homeRoot}>
      <PageHeader
        title="OUR COURSES"
        description="Get certified with our state of the art online and classroom training courses"
      />
      <Spacer spacing={4} vertical />
      <PageMargin>
        <CarouselWithDemo
          noDemo
          heading="Explore our popular courses"
          courses={courses}
        />
      </PageMargin>
      <PageMargin centererStyle={classes.greySpacer}>
        <div className={classes.heading}>
          Explore all of our online course categories
        </div>
        <div className={classes.rowSmall}>
          {onlineCategories?.map((cat) => (
            <div className={classes.card}>
              <BrandCard
                title={cat?.name}
                imageURL={buildURL(cat?.imageURL ?? '', {
                  w: 400,
                  h: 300,
                  fit: 'crop',
                })}
                text={cat?.description}
                linkText={`View ${cat?.name} Courses`}
                link={`/courses/${cat?.uuid}`}
                className={classes.thinItem}
              />
            </div>
          ))}
        </div>
        <div className={classes.heading}>
          Explore all of our classrooom course categories
        </div>
        <div className={classes.rowBig}>
          {classroomCategories?.map((cat) => (
            <div className={classes.longCard}>
              <BrandCard
                title={cat?.name}
                imageURL={buildURL(cat?.imageURL ?? '', {
                  w: 500,
                  h: 300,
                  fit: 'crop',
                })}
                text={cat?.description}
                linkText={`View ${cat?.name} Courses`}
                link={`/courses/${cat?.uuid}`}
                className={classes.longItem}
              />
            </div>
          ))}
        </div>
      </PageMargin>
      <PageMargin>
        <div className={classNames(classes.heading, classes.smallMargin)}>
          Our four-step training process
        </div>
        <FourPanel
          className={classes.marginBottom}
          panels={defaultImagePanels}
          noBorders
        />
      </PageMargin>
      <PeopleCurve />
    </div>
  );
}

export default createFragmentContainer(ExploreCourses, {
  popularCourses: graphql`
    fragment ExploreCourses_popularCourses on Course @relay(plural: true) {
      ident: id
      name
      price
      excerpt
      introduction
      bannerImageURL
      category {
        name
        color
      }
    }
  `,
  categories: graphql`
    fragment ExploreCourses_categories on CategoryPage {
      edges {
        uuid
        name
        description
        color
        classroomOnly
        imageURL
      }
      pageInfo {
        total
      }
    }
  `,
});
