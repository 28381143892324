import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import Homepage from 'components/Overview/Homepage';
import FloatingVideo from 'components/core/VideoPlayer/FloatingVideo';
import ImageWithText from 'components/core/ImageWithText';
import Spacer from 'sharedComponents/core/Spacers/Spacer';
import { Router } from 'found';
import { Course } from 'sharedComponents/Overview/CourseCard';
import CarouselWithDemo from 'components/Misc/CarouselCourse/CarouselWithDemo';
import PageMargin from 'components/core/PageMargin';
import PeopleCurve from 'components/core/Curve/PeopleCurve';
import { createFragmentContainer, graphql } from 'react-relay';
import { Home_popularCourses } from './__generated__/Home_popularCourses.graphql';

const useStyles = createUseStyles((theme: Theme) => ({
  homeRoot: {
    width: '100%',
    background: 'white',
  },
  whiteSpacer: {
    background: theme.colors.primaryWhite,
    padding: '60px 0px 100px 0px',
  },
  heading: {
    fontSize: 32,
    color: theme.colors.primaryBlack,
    fontWeight: 800,
    padding: '60px 0px',
    textAlign: 'center',
  },
  buttonHolder: {
    display: 'flex',
  },
  button: {
    height: 52,
    fontSize: 18,
    fontWeight: 800,
    boxShadow: '0px 2px 9px #00000014',
    padding: '0px 36px',
  },
  quote: {
    margin: '0 0 70px 89px',
    flex: 1,
  },
}));

type Props = {
  router: Router;
  popularCourses: Home_popularCourses;
};

function Home({ router, popularCourses }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  console.log('course', popularCourses);

  const courses = popularCourses.map((course) => ({
    id: course.ident,
    type: course?.category?.name ?? '',
    colour: course?.category?.color ?? '',
    url: course.bannerImageURL ?? '',
    title: course.name,
    price: course.price,
    description: course.excerpt ?? '',
    onClick: () => router.push('/course/' + course.ident),
    // location: 'TTC at Hilton T4',
    // modules: 16,
    // lessons: 144,
    // videoTime: 4,
  }));

  return (
    <div className={classes.homeRoot}>
      <Homepage
        imageURL=""
        onView={() => {
          window.scrollTo(0, 0);
          router.push('/courses');
        }}
        onDemo={() => window.open('https://calendly.com/ttc-hub', '_blank')}
      />
      <PageMargin>
        <CarouselWithDemo
          heading="Explore our popular courses"
          description="Empower your team with TTC knowledge"
          courses={courses}
        />
      </PageMargin>

      <PageMargin centererStyle={classes.whiteSpacer}>
        <FloatingVideo
          width={613}
          image
          source={require('assets/testimonial1.jpg')}
          logo={require('assets/evcargo.png')}
          author={{
            name: 'Andy Ramsay',
            title: 'Head of Warehouse Operations \nEV Cargo',
            quote:
              'The Training and Compliance Hub excels in providing the best up to date training based on the latest training syllabi issued by the CAA. Their online training platform is second to none, with its modern look and user-friendly features. Their staff are extremely knowledgeable and I can always rely on them to assist with any regulatory or compliance queries. It’s great to have such a friendly and professional company looking after our training and compliance needs.',
          }}
        />
      </PageMargin>
      <PeopleCurve />
    </div>
  );
}

export default createFragmentContainer(Home, {
  popularCourses: graphql`
    fragment Home_popularCourses on Course @relay(plural: true) {
      ident: id
      name
      price
      excerpt
      introduction
      bannerImageURL
      category {
        name
        color
      }
    }
  `,
});
