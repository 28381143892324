import React from 'react';

function LinkedIn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      data-name="Group 1671"
      viewBox="0 0 76.7 65.3"
    >
      <path
        fill="#1d2b37"
        d="M72.9 61.1a1.155 1.155 0 001.2-1.2v-.1c0-.9-.5-1.3-1.7-1.3h-1.8v4.7h.7v-2.1h.8l1.3 2h.7l-1.2-2zm-.8-.5h-.8V59h1c.5 0 1.1.1 1.1.8 0 .8-.6.8-1.3.8"
      ></path>
      <path
        fill="#1d2b37"
        d="M55.2 55.2h-9.6v-15c0-3.6-.1-8.2-5-8.2-5 0-5.8 3.9-5.8 7.9v15.3h-9.6V24.3h9.2v4.2h.1a10.265 10.265 0 019.1-5c9.7 0 11.5 6.4 11.5 14.7zM14.4 20.1a5.6 5.6 0 115.6-5.6 5.591 5.591 0 01-5.6 5.6m4.8 35.1H9.6V24.3h9.6zM60 0H4.8A4.781 4.781 0 000 4.7v55.4a4.781 4.781 0 004.8 4.7H60a4.781 4.781 0 004.8-4.7V4.7A4.781 4.781 0 0060 0"
      ></path>
      <path
        fill="#1d2b37"
        d="M72.2 56.4a4.45 4.45 0 104.5 4.4 4.527 4.527 0 00-4.5-4.4m0 8.3a3.885 3.885 0 01-3.9-3.8A3.8 3.8 0 0172 57a3.885 3.885 0 013.9 3.8v.1a3.866 3.866 0 01-3.7 3.9v-.1"
      ></path>
    </svg>
  );
}

export default LinkedIn;
