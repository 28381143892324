import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import { useRouter } from 'found';
import { Theme } from 'helpers/theme';
import CircleBorder from 'sharedComponents/core/CircleBorder';
import Card, { PaddingOptions } from 'sharedComponents/core/Cards/Card';

const useStyles = createUseStyles((theme: Theme) => ({
  mainRoot: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  text: {
    fontSize: theme.fontSizes.default,
    color: theme.colors.textGrey,
    margin: '20px 0 15px 0',
  },
  quote: {
    fontSize: theme.fontSizes.tinyHeading,
    fontStyle: 'italic',
    color: '#34373A',
    fontWeight: '600',
    maxWidth: '270px',
    margin: '15px 0',
    '@media (max-width: 500px)': {
      textAlign: 'center'
    },
  },
  quotationMarks: {
    height: '43px',
    fontSize: '75px',
    fontWeight: '800',
    color: theme.colors.navyBlue,
    '@media (max-width: 500px)': {
      alignSelf: 'center'
    },
  },
  name: {
    fontSize: theme.fontSizes.xSmall,
    fontWeight: '600',
    marginBottom: '3px',
  },
  title: {
    fontSize: theme.fontSizes.xSmall,
    fontWeight: '600',
    color: theme.colors.textGrey,
  },
  header: {
    fontSize: theme.fontSizes.extraLarge,
    fontWeight: '800',
    color: theme.colors.primaryBlack,
  },
  profilePicture: {
    marginRight: '10.5px',
  },
  link: {
    cursor: 'pointer',
    fontSize: theme.fontSizes.default,
    fontWeight: 'bold',
    color: theme.colors.navyBlue,
    paddingBottom: '5px',
    borderBottom: ['1.5px', 'solid', theme.colors.borderGrey],
  },
  logoContainer: {
    display: 'flex',
    // flex: 1,
  },
  image: {
    maxHeight: '250px',
    width: '100%',
  },
  logo: {
    maxWidth: '200px',
    maxHeight: '60px',
    alignSelf: 'flex-start',
    '@media (max-width: 500px)': {
      width: '100%',
    },
  },
  quoteLogo: {
    maxWidth: '200px',
    maxHeight: 59,
    alignSelf: 'flex-start',
    '@media (max-width: 500px)': {
      //width: '100%',
      alignSelf: 'center'
    },
    margin: '25px 0 0px 0',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noRound: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
}));

export type Author = {
  name: string;
  title: string;
  quote: string;
};

type Props = {
  imageURL?: string;
  logoURL?: string;
  title?: string;
  link: string;
  linkText?: string;
  text?: string;
  author?: Author;
  padding?: PaddingOptions;
  className?: string;
};

function BrandCard({
  imageURL,
  logoURL,
  link,
  title,
  text,
  linkText = 'Read their story',
  author,
  padding = 'large',
  className,
}: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const { router } = useRouter();
  const onClick = (link?: string) => {
    link && router.push(link);
  };

  return (
    <div className={classes.root}>
      {imageURL && <img src={imageURL} className={classes.image} />}
      <Card
        padding={padding}
        className={classNames(
          classes.mainRoot,
          imageURL && classes.noRound,
          className,
        )}
      >
        {author ? (
          <div className={classes.root}>
            <div className={classes.quotationMarks}>“</div>
            <div className={classes.quote}>{author.quote}</div>
            <div className={classes.row}>
              <CircleBorder
                borderType="plain"
                user={{ name: author.name }}
                className={classes.profilePicture}
              />
              <div>
                <div className={classes.name}>{author.name}</div>
                <div className={classes.title}>{author.title}</div>
              </div>
            </div>
            <img src={logoURL} className={classes.quoteLogo} />
            <div className={classes.link} onClick={() => onClick(link)}>
              {linkText}
            </div>
          </div>
        ) : (
          <div
            className={classes.root}
            style={imageURL ? { height: '150px' } : {}}
          >
            <div className={classes.logoContainer}>
              {title && <div className={classes.header}>{title}</div>}
              {logoURL && <img src={logoURL} className={classes.logo} />}
            </div>
            <div className={classes.text}>{text}</div>
            <div className={classes.link} onClick={() => onClick(link)}>
              {linkText}
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}

export default BrandCard;
