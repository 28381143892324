import * as React from 'react';
import classNames from 'classnames';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';

const useStyles = createUseStyles((theme: Theme) => ({
  pageHeading: {
    fontSize: theme.fontSizes.extraLargeHeading,
    color: theme.colors.primaryBlack,
    fontWeight: '800',
  },
  pageSubheading: {
    fontSize: theme.fontSizes.xSmallHeading,
    color: theme.colors.textGrey,
    fontWeight: '200',
    margin: '15px 0',
  },
  lastUpdated: {
    fontSize: theme.fontSizes.default,
    color: theme.colors.textGrey,
    fontWeight: '200',
    paddingBottom: '10px',
    borderBottom: ['1px', 'solid', '#D8D8D8'],
    marginBottom: '25px',
  },
  textHeading: {
    fontSize: theme.fontSizes.heading,
    color: theme.colors.primaryBlack,
    fontWeight: '800',
    margin: '30px 0 10px 0',
  },
  text: {
    fontSize: theme.fontSizes.large,
    color: '#404349',
    fontWeight: '200',
    margin: '17px 0',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: '50px',
  },
  marginBottom: {
    marginBottom: '100px',
  },
  bulletFilled: {
    minHeight: '8px',
    minWidth: '8px',
    borderRadius: '8px',
    backgroundColor: theme.colors.textBlue,
    margin: '20px',
  },
}));

type Props = {};

function SubProcessors(props: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div>
      <div className={classes.textHeading}>Sub-Processes</div>
      <div className={classes.lastUpdated}>
        Last revised on the 1st Jan 2023, and effective as of that date
      </div>
      <div className={classes.text}>Sub-Processes we use:</div>
      <div className={classes.row}>
        <div className={classes.bulletFilled} />
        <div className={classes.text}>Amazon AWS (Cloud Infrastructure)</div>
      </div>
      <div className={classes.row}>
        <div className={classes.bulletFilled} />
        <div className={classes.text}>Stripe (Payment)</div>
      </div>
      <div className={classes.row}>
        <div className={classes.bulletFilled} />
        <div className={classes.text}>Sentry (Logging)</div>
      </div>
      <div className={classNames(classes.row, classes.marginBottom)}>
        <div className={classes.bulletFilled} />
        <div className={classes.text}>Imgix (Image Processing)</div>
      </div>
    </div>
  );
}

export default SubProcessors;
