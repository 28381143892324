import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classnames from 'classnames';
import { Theme } from 'helpers/theme';
import FloatingVideo from 'components/core/VideoPlayer/FloatingVideo';
import Spacer from 'sharedComponents/core/Spacers/Spacer';
import PageHeader from 'components/core/PageHeader';
import Quote from 'components/Overview/Article/Quote';
import Card from 'sharedComponents/core/Cards/Card';
import PageMargin from 'components/core/PageMargin';
import { useRouter } from 'found';

const useStyles = createUseStyles((theme: Theme) => ({
  aboutRoot: {
    width: '100%'
  },
  textCenter: {
    maxWidth: '821px'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  blueLogo: {
    width: 70,
  },
  centeredLogoItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: '50px',
  },
  logoText: {
    fontSize: theme.fontSizes.heading,
    maxWidth: 500,
  },
  subheading: {
    color: '#34373A',
    fontSize: theme.fontSizes.heading,
    fontWeight: 600,
    marginBottom: '50px',
  },
  heading: {
    fontSize: theme.fontSizes.heading,
    fontWeight: 600,
    marginBottom: '25px',
  },
  text: {
    color: theme.colors.textGrey,
    fontSize: theme.fontSizes.tinyHeading,
  },
  link: {
    cursor: 'pointer',
    color: theme.colors.textBlue,
  },
  image: {
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '879px',
    margin: '90px 0 100px 0',
  },
  video: {
    position: 'relative',
    width: '100vw',
    overflow: 'hidden',
    margin: '100px 0px',
    '@media (max-width: 750px)': {
      width: '95vw',
      boxShadow: 'none',
      margin: '5% 0',
    },
  },
  quote: {
    margin: '0 0 70px 89px',
    flex: 1,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '100px 0',
    textAlign: 'center',
  },
  cardText: {
    maxWidth: '575px',
  },
  centerText: {
    textAlign: 'center',
  },
}));

type Props = {};

function AboutUs({}: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { router } = useRouter();
  return (
    <div className={classes.aboutRoot}>
      <PageHeader
        title="ABOUT US"
        description="Our mission is to create the highest quality safety and compliance training in the world"
      />
      <Spacer spacing={4} vertical />
      <PageMargin>
        <div className={classes.centeredLogoItems}>
          <img
            src={require('assets/logo/blueCircleLogo.svg')}
            className={classes.blueLogo}
          />
          <h2 className={classes.logoText}>
            TTC was created with a passion for excellence
          </h2>
        </div>
        <div className={classes.textCenter}>
          <div className={classnames(classes.subheading, classes.centerText)}>
            Our team includes some of the most knowledgeable and experienced
            professionals in the industry. We are passionate about excellent
            customer service and believe that this is what sets us apart. We
            work in partnership with you to set the foundations for a successful
            business.
          </div>
          <div className={classes.heading}>A new league of compliance</div>
          <div className={classes.text}>
            We offer great quality cost-effective training, compliance and
            consultancy services in both aviation security and dangerous goods.
            Alongside this, is a range of online compliance training including
            health and safety.
          </div>
        </div>
        <img className={classes.image} src={require('assets/aboutUs.png')} />
        <div className={classes.textCenter}>
          <div className={classes.heading}>Your success means everything</div>
          <div className={classes.text}>
            Our aim is simple, if you are successful, we are successful. We
            pride ourselves on our customer care, always going the extra mile
            to ensure your peace of mind, whilst ensuring the paramount task of
            compliance is well taken care of.
            <br></br>
            <br></br>Our platform is designed with your compliance at the
            forefront. As well as interactive notifications to prompt your
            action, we also designed the most visually appealing dashboard to
            make the management of your team as easy as it can be!
          </div>
          <Spacer vertical spacing={3} />
          <div className={classes.heading}>
            We’re building the future of online training
          </div>
          <div className={classes.row}>
            <div className={classes.text} style={{ flex: 2 }}>
              Our courses are not just engaging and fun to do, they also include
              the most up-to-date information as our team are in close
              communication with relevant regulators and authorities.
              <br></br>
              <br></br>We make sure that our clients are always kept informed of
              the latest requirements, whether via our courses or our
              consultancy services.
            </div>
            <Quote
              className={classes.quote}
              borderSide="top"
              quote="“Whilst growth is key, development and compliance within your team are paramount.”"
            />
          </div>
          <Card className={classes.card} padding="large">
            <div className={classes.heading}>We’re hiring.</div>
            <div className={classnames(classes.text, classes.cardText)}>
              We’re always looking for people to raise the bar and help us
              deliver an even better service to customers.{' '}
              <span
                className={classes.link}
                onClick={() => router.push('/contact')}
              >
                Join us!
              </span>
            </div>
          </Card>
        </div>
      </PageMargin>
    </div>
  );
}

export default AboutUs;
