import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';
import Button from 'sharedComponents/core/Input/Button';
import ImageWithText from 'components/core/ImageWithText';
import Spacer from 'sharedComponents/core/Spacers/Spacer';
import { Router } from 'found';
import { Course } from 'sharedComponents/Overview/CourseCard';
import PageMargin from 'components/core/PageMargin';
import PeopleCurve from 'components/core/Curve/PeopleCurve';
import PageHeader from 'components/core/PageHeader';

const useStyles = createUseStyles((theme: Theme) => ({
  homeRoot: {
    width: '100%',
    background: 'white',
  },
  whiteSpacer: {
    background: theme.colors.primaryWhite,
    padding: '60px 0px 100px 0px',
  },
  heading: {
    fontSize: 32,
    color: theme.colors.primaryBlack,
    fontWeight: 800,
    padding: '60px 0px',
    textAlign: 'center',
  },
  subheading: {
    fontSize: theme.fontSizes.extraLarge,
    color: theme.colors.secondaryBlack,
    fontWeight: 800,
    padding: '60px 0px',
    textAlign: 'center',
  },
  buttonHolder: {
    display: 'grid',
    gridTemplateColumns: '1fr 75px 1fr',
    gridSpace: '50px',
    '@media (max-width: 575px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr 1fr',
    },
  },
  text: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: theme.fontSizes.extraLarge,
    color: theme.colors.primaryBlack,
  },
  button: {
    height: 52,
    fontSize: 18,
    fontWeight: 800,
    boxShadow: '0px 2px 9px #00000014',
    padding: '0px 36px',
  },
}));

type Props = {
  router: Router;
};

const defaultCourse: Course = {
  id: 2,
  type: 'DANGEROUS GOODS AIR',
  colour: '#8C1CB4',
  url: require('../assets/SampleImage_ClassroomCoursesDetail_Feat.png'),
  title: 'Dangerous goods by air category 7',
  price: 60,
  description:
    'This course is for those involved in the handling, storage and loading of cargo or mail and baggage.',
  assigned: 40,
  expiring: 9,
  date: 'MAR 3rd 2020',
  location: 'TTC at Hilton T4',
  modules: 16,
  lessons: 144,
  videoTime: 4,
};

function IndividualFeatures({ router }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.homeRoot}>
      <PageHeader
        title="FEATURES"
        description="The training tool built to help you develop your career"
      />
      <PageMargin centererStyle={classes.whiteSpacer}>
        <div className={classes.heading}>
          So, how can individuals benefit from the new TTC Hub platform?
        </div>
        <ImageWithText
          subtitle="Create your learning hub"
          description="Create your own account where you can access your courses and certificates, and where you can track your career development progress. "
          image={require('assets/create_your_learning_hub.png')}
          link={{ title: 'See Online Courses', link: '/courses' }}
        />
        <Spacer spacing={5} vertical />
        <ImageWithText
          subtitle="Get certified online"
          description="It is quick and easy to get yourself certified with our state of the art courses. Booking, completion and certification of courses are instantaneous upon completion of each stage. "
          image={require('assets/june_heres_your_cert.png')}
          link={{
            title: 'Register now for instant access',
            link: '/register/individual',
          }}
          textRight
        />
        <Spacer spacing={5} vertical />
        <ImageWithText
          subtitle="Manage your development"
          description="Keep up to date with your training. Our system will notify you when training needs to be revalidated. Our bespoke dashboard gives a clear snap shot of the current state of your compliance."
          image={require('assets/keep_track_of_progress.png')}
          link={{ title: 'Register now', link: '/register/individual' }}
        />
        <Spacer spacing={5} vertical />
        <div className={classes.subheading}>
          Up-skill with online training from TTC Hub, today.
        </div>
        <div className={classes.buttonHolder}>
          <Button
            archetype="gradient"
            className={classes.button}
            onClick={() => router.push('/register/individual')}
          >
            Register now
          </Button>
        <div className={classes.text}>OR</div>
          <Button
            archetype="default"
            className={classes.button}
            onClick={() => window.open('https://calendly.com/ttc-hub', '_blank')}
          >
            Request Demo
          </Button>
        </div>
      </PageMargin>
      <PeopleCurve />
    </div>
  );
}

export default IndividualFeatures;
