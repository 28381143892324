import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import { Theme } from 'helpers/theme';
import CoursePageHeader from 'components/core/PageHeader/CoursePageHeader';
import CoursePreview from 'components/Misc/CoursePreview';
import TickBullet from 'components/Misc/TickBullet';
import CarouselWithDemo from 'components/Misc/CarouselCourse/CarouselWithDemo';
import { Course } from 'sharedComponents/Overview/CourseCard';
import PageMargin from 'components/core/PageMargin';
import { createFragmentContainer, graphql } from 'react-relay';
import { CourseDetailsPage_course } from './__generated__/CourseDetailsPage_course.graphql';
import Spacer from 'sharedComponents/core/Spacers/Spacer';
import { CourseDetailsPage_user } from './__generated__/CourseDetailsPage_user.graphql';
import { useRouter } from 'found';
import {
  delegateLogin,
  managerLogin,
  delegateSite,
  managerSite,
} from 'api/config';
import { CourseDetailsPage_popularCourses } from './__generated__/CourseDetailsPage_popularCourses.graphql';
import moment from 'moment';

const useStyles = createUseStyles((theme: Theme) => ({
  courseRoot: {
    width: '100%',
  },
  centerer: {
    display: 'flex',
    justifyContent: 'center',
  },
  centered: {
    flexDirection: 'row',
  },
  column: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '@media (max-width: 1000px)': {
      gridTemplateColumns: '1fr',
    },
  },
  heading: {
    fontSize: theme.fontSizes.tinyHeading,
    color: theme.colors.primaryBlack,
    fontWeight: 'bold',
    padding: '40px 0px 20px 0px',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fontSizes.extraLarge,
    color: theme.colors.secondaryBlack,
  },
  bullet: {
    maxHeight: '10px',
    maxWidth: '11.5px',
    minHeight: '10px',
    minWidth: '11.5px',
    borderRadius: '10px',
    margin: '12.5px',
    backgroundColor: theme.colors.textBlue,
  },
  tickMargin: {
    margin: '15px',
    alignItems: 'center',
  },
  marginBottom: {
    marginBottom: '100px',
  },
  mainColumn: {
    flex: 1.8,
  },
  spacer: {
    flex: 1,
    '@media (min-width: 850px) and (max-width: 1300px)': {
      flex: 2,
    },
    '@media (max-width: 850px)': {
      display: 'none',
    },
  },
  carousel: {
    width: '90vw',
    marginTop: '50px',
    paddingBottom: '50px',
  },
  whiteBackground: {
    background: theme.colors.primaryWhite,
  },
}));

// Course Preview data
const defaultDetails: string[] = [
  'Access to your personalised account',
  'Access on mobile, tablet and computer',
  'Required certification',
];

type Props = {
  course: CourseDetailsPage_course;
  user?: CourseDetailsPage_user;
  popularCourses: CourseDetailsPage_popularCourses;
};

function CourseDetailsPage({ course, user, popularCourses }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { router } = useRouter();

  const courses = popularCourses.map((course) => ({
    id: course.ident,
    type: course?.category?.name ?? '',
    colour: course?.category?.color ?? '',
    url: course.bannerImageURL ?? '',
    title: course.name,
    price: course.price,
    description: course.excerpt ?? '',
    onClick: () => router.push('/course/' + course.ident),
    // location: 'TTC at Hilton T4',
    // modules: 16,
    // lessons: 144,
    // videoTime: 4,
  }));

  const toDays = course.hoursToComplete
    ? Math.round((course.hoursToComplete / 24) * 10) / 10
    : 0;
  const toDayText = toDays > 1 ? `${toDays} days` : `${toDays} day`;

  const toHours = course.hoursToComplete ? course.hoursToComplete : 0;
  const toHoursText = toHours > 1 ? `${toHours} hours` : `${toHours} hour`;

  return (
    <div className={classes.courseRoot}>
      <CoursePageHeader
        title={course?.name ?? ''}
        description={course?.excerpt ?? ''}
        history={[
          'Courses',
          course.category?.name || '',
          course.secondaryCategory?.name ?? '',
        ]}
        estimatedTime={toDays >= 1 ? toDayText : toHoursText}
        lastUpdated={moment(course.updatedAt).format('MMMM YYYY')}
        price={`£${course.price}`}
        image={course.bannerImageURL ?? undefined}
        classroomDates={course.instances?.edges?.map((inst) => {
          return inst?.startDate ?? '';
        })}
        sideComponent={
          <CoursePreview
            price={`£${course.price}`}
            details={defaultDetails}
            image={course.bannerImageURL ?? undefined}
            isLoggedin={!!user}
            isAllowedToPurchase={course.allowedToBuy ?? true}
            classroomDates={course.instances?.edges?.map((inst) => {
              return inst?.startDate ?? '';
            })}
            onPurchaseClick={() => {
              switch (user?.type) {
                case 'delegate':
                  window.location.href = `${delegateSite}/app?course=${course.ident}`;
                  break;
                case 'individual':
                  window.location.href = `${delegateSite}/app?course=${course.ident}`;
                  break;
                case 'manager':
                  window.location.href = `${managerSite}/app?course=${course.ident}`;
                  break;
              }
            }}
            onLoginClick={() => {
              window.location.href = delegateLogin;
            }}
          />
        }
      />
      <PageMargin
        centererStyle={classes.whiteBackground}
        centeredStyle={classes.centered}
      >
        <div className={classes.mainColumn}>
          <div className={classes.heading}>What you’ll learn</div>
          <div className={classes.column}>
            {course.whatYouLearn?.map((text) => (
              <TickBullet className={classes.tickMargin} text={text} />
            ))}
          </div>
          {/* <div className={classes.heading}>
            About this Course – Estimated time to complete{' '}
            {course.hoursToComplete} hours
          </div>
          <div className={classes.text}>{course.introduction}</div> */}
          <div className={classes.heading}>How to complete this Course</div>
          <div className={classes.text}>{course.howToComplete}</div>
          <div className={classes.heading}>Requirements</div>
          {course.requirements?.map((text) => (
            <div className={classes.text}>
              <div className={classes.bullet} />
              {text}
            </div>
          ))}
          <Spacer vertical spacing={3} />
        </div>
        <div className={classes.spacer} />
      </PageMargin>
      <div className={classes.centerer}>
        <CarouselWithDemo
          className={classes.carousel}
          heading="Explore our popular courses"
          description="Empower your team with TTC knowledge"
          courses={courses}
        />
      </div>
    </div>
  );
}

export default createFragmentContainer(CourseDetailsPage, {
  course: graphql`
    fragment CourseDetailsPage_course on Course
      @argumentDefinitions(afterDate: { type: "Time" }) {
      ident: id
      name
      updatedAt
      price
      excerpt
      introduction
      bannerImageURL
      price
      howToComplete
      hoursToComplete
      whatYouLearn
      requirements
      allowedToBuy
      accessType
      category {
        name
        color
      }
      secondaryCategory {
        name
      }
      ... on ClassroomCourse {
        instances(filter: { afterDate: $afterDate }) {
          edges {
            startDate
            endDate
            location
          }
        }
      }
    }
  `,
  user: graphql`
    fragment CourseDetailsPage_user on User {
      firstName
      type
    }
  `,
  popularCourses: graphql`
    fragment CourseDetailsPage_popularCourses on Course @relay(plural: true) {
      ident: id
      name
      price
      excerpt
      introduction
      bannerImageURL
      category {
        name
        color
      }
    }
  `,
});
