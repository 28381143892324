import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import { Theme } from 'helpers/theme';
import VideoPlayer from './VideoPlayer';
import Icon from 'sharedComponents/core/Icon';

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    '@media (max-width: 1000px)': {
      // Allows correct resizing
      flexDirection: (hasQuote: boolean) => (hasQuote ? 'column' : 'row'),
      alignItems: 'center',
    },
  },
  floating: {
    position: 'absolute',
    borderRadius: '5px',
    boxShadow:
      '5px 15px 43px 8px rgba(143,143,173,0.28), 10px 27px 21px 3px rgba(0,0,0,0.06)',
    overflow: 'hidden',
    '@media (max-width: 1000px)': {
      position: 'relative',
    },
  },
  bottomBox: {
    display: 'flex',
    height: 'auto',
    padding: '41px 0px',
    width: '100%',
    margin: '52px 0 0 316px',
    borderRadius: '6px',
    backgroundColor: theme.colors.primaryWhite,
    boxShadow: '0 3px 15px 1px rgba(0,0,0,0.12)',
    '@media (max-width: 1000px)': {
      width: '100%',
      margin: '0',
    },
  },
  quoteContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 68px 0 40%',
    '@media (max-width: 1000px)': {
      margin: 47,
    },
  },
  quote: {
    fontSize: '21px',
    color: theme.colors.textGrey,
    fontWeight: '300',
    marginBottom: '40px',
  },
  authorName: {
    fontSize: theme.fontSizes.tinyHeading,
    fontWeight: 'bold',
    whiteSpace: 'pre-wrap',
  },
  authorTitle: {
    fontSize: theme.fontSizes.extraLarge,
    color: theme.colors.textGrey,
    whiteSpace: 'pre-wrap',
  },
  logo: {
    marginLeft: '34px',
    height: '60px',
    maxWidth: '200px',
    '@media (max-width: 500px)': {
      margin: '25px 0 0 0',
      alignSelf: 'center'
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media (max-width: 500px)': {
      flexDirection: 'column',
    },
  },
}));

export type Author = {
  name: string;
  title: string;
  quote: string;
};

type Props = {
  source: string;
  image?: boolean;
  height?: number;
  width?: number;
  thumbnail?: React.ReactNode;
  author?: Author;
  className?: string;
  logo?: string;
};

function FloatingVideo({
  source,
  image,
  height,
  width,
  author,
  thumbnail,
  className,
  logo,
}: Props) {
  const theme = useTheme();
  const classes = useStyles({ hasQuote: author !== undefined, theme });

  return (
    <div className={classes.root}>
      <div className={classNames(classes.floating, className)}>
        <VideoPlayer
          source={source}
          image={image}
          height={height}
          width={width}
          thumbnail={thumbnail}
        />
      </div>
      {author && (
        <div className={classes.bottomBox}>
          <div className={classes.quoteContainer}>
            <div className={classes.quote}>"{author.quote}"</div>
            <div className={classes.row}>
              <div>
                <div className={classes.authorName}>{author.name}</div>
                <div className={classes.authorTitle}>{author.title}</div>
              </div>
              {logo ? (
                <img src={logo} className={classes.logo} />
              ) : (
                <Icon
                  name="TTC_Logo_Icon"
                  className={classes.logo}
                  style={{ height: '39px', width: '127px' }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FloatingVideo;
