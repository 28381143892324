/* tslint:disable */
/* eslint-disable */
/* @relayHash 952292c9ad03dea1381c2de00ef23058 */

import { ConcreteRequest } from "relay-runtime";
export type App_Article_QueryVariables = {
    id: string;
};
export type App_Article_QueryResponse = {
    readonly blog: {
        readonly uuid: string;
        readonly title: string;
        readonly body: string;
        readonly createdAt: string;
        readonly category: {
            readonly name: string;
        };
        readonly headerImageURL: string | null;
    } | null;
};
export type App_Article_Query = {
    readonly response: App_Article_QueryResponse;
    readonly variables: App_Article_QueryVariables;
};



/*
query App_Article_Query(
  $id: UUID!
) {
  blog(uuid: $id) {
    uuid
    title
    body
    createdAt
    category {
      name
    }
    headerImageURL
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "kind": "LocalArgument",
            "name": "id",
            "type": "UUID!",
            "defaultValue": null
        } as any)
    ], v1 = [
        ({
            "kind": "LinkedField",
            "alias": null,
            "name": "blog",
            "storageKey": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "uuid",
                    "variableName": "id"
                }
            ],
            "concreteType": "Blog",
            "plural": false,
            "selections": [
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "uuid",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "body",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "createdAt",
                    "args": null,
                    "storageKey": null
                },
                {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "category",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Category",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                        }
                    ]
                },
                {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "headerImageURL",
                    "args": null,
                    "storageKey": null
                }
            ]
        } as any)
    ];
    return {
        "kind": "Request",
        "fragment": {
            "kind": "Fragment",
            "name": "App_Article_Query",
            "type": "Query",
            "metadata": null,
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "operation": {
            "kind": "Operation",
            "name": "App_Article_Query",
            "argumentDefinitions": (v0 /*: any*/),
            "selections": (v1 /*: any*/)
        },
        "params": {
            "operationKind": "query",
            "name": "App_Article_Query",
            "id": null,
            "text": "query App_Article_Query(\n  $id: UUID!\n) {\n  blog(uuid: $id) {\n    uuid\n    title\n    body\n    createdAt\n    category {\n      name\n    }\n    headerImageURL\n  }\n}\n",
            "metadata": {}
        }
    } as any;
})();
(node as any).hash = '6d22033ea8bc3c9be8b159ab1e27536f';
export default node;
