/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AccessType = "open" | "restricted" | "%future added value";
export type CourseDetailsPage_course = {
    readonly ident: number;
    readonly name: string;
    readonly updatedAt: string;
    readonly price: number;
    readonly excerpt: string | null;
    readonly introduction: string | null;
    readonly bannerImageURL: string | null;
    readonly howToComplete: string | null;
    readonly hoursToComplete: number | null;
    readonly whatYouLearn: ReadonlyArray<string> | null;
    readonly requirements: ReadonlyArray<string> | null;
    readonly allowedToBuy: boolean | null;
    readonly accessType: AccessType;
    readonly category: {
        readonly name: string;
        readonly color: string;
    } | null;
    readonly secondaryCategory: {
        readonly name: string;
    } | null;
    readonly instances?: {
        readonly edges: ReadonlyArray<{
            readonly startDate: string;
            readonly endDate: string;
            readonly location: string;
        } | null> | null;
    } | null;
    readonly " $refType": "CourseDetailsPage_course";
};
export type CourseDetailsPage_course$data = CourseDetailsPage_course;
export type CourseDetailsPage_course$key = {
    readonly " $data"?: CourseDetailsPage_course$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseDetailsPage_course">;
};



const node: ReaderFragment = (function () {
    var v0 = ({
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
    } as any);
    return {
        "kind": "Fragment",
        "name": "CourseDetailsPage_course",
        "type": "Course",
        "metadata": null,
        "argumentDefinitions": [
            {
                "kind": "LocalArgument",
                "name": "afterDate",
                "type": "Time",
                "defaultValue": null
            }
        ],
        "selections": [
            {
                "kind": "ScalarField",
                "alias": "ident",
                "name": "id",
                "args": null,
                "storageKey": null
            },
            (v0 /*: any*/),
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "updatedAt",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "price",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "excerpt",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "introduction",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "bannerImageURL",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "howToComplete",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "hoursToComplete",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "whatYouLearn",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "requirements",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "allowedToBuy",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "ScalarField",
                "alias": null,
                "name": "accessType",
                "args": null,
                "storageKey": null
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "category",
                "storageKey": null,
                "args": null,
                "concreteType": "Category",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "color",
                        "args": null,
                        "storageKey": null
                    }
                ]
            },
            {
                "kind": "LinkedField",
                "alias": null,
                "name": "secondaryCategory",
                "storageKey": null,
                "args": null,
                "concreteType": "SecondaryCategory",
                "plural": false,
                "selections": [
                    (v0 /*: any*/)
                ]
            },
            {
                "kind": "InlineFragment",
                "type": "ClassroomCourse",
                "selections": [
                    {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "instances",
                        "storageKey": null,
                        "args": [
                            {
                                "kind": "ObjectValue",
                                "name": "filter",
                                "fields": [
                                    {
                                        "kind": "Variable",
                                        "name": "afterDate",
                                        "variableName": "afterDate"
                                    }
                                ]
                            }
                        ],
                        "concreteType": "ClassroomInstancePage",
                        "plural": false,
                        "selections": [
                            {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ClassroomInstance",
                                "plural": true,
                                "selections": [
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "startDate",
                                        "args": null,
                                        "storageKey": null
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "endDate",
                                        "args": null,
                                        "storageKey": null
                                    },
                                    {
                                        "kind": "ScalarField",
                                        "alias": null,
                                        "name": "location",
                                        "args": null,
                                        "storageKey": null
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    } as any;
})();
(node as any).hash = '773aa2d058f6484418a5841406219d74';
export default node;
