import * as React from 'react';
import classNames from 'classnames';
import { createUseStyles, useTheme } from 'react-jss';
import { Theme } from 'helpers/theme';

const useStyles = createUseStyles((theme: Theme) => ({
  pageHeading: {
    fontSize: theme.fontSizes.extraLargeHeading,
    color: theme.colors.primaryBlack,
    fontWeight: '800',
  },
  pageSubheading: {
    fontSize: theme.fontSizes.xSmallHeading,
    color: theme.colors.textGrey,
    fontWeight: '200',
    margin: '15px 0',
  },
  lastUpdated: {
    fontSize: theme.fontSizes.default,
    color: theme.colors.textGrey,
    fontWeight: '200',
    paddingBottom: '10px',
    borderBottom: ['1px', 'solid', '#D8D8D8'],
    marginBottom: '25px',
  },
  textHeading: {
    fontSize: theme.fontSizes.heading,
    color: theme.colors.primaryBlack,
    fontWeight: '800',
    margin: '30px 0 10px 0',
  },
  text: {
    fontSize: theme.fontSizes.large,
    color: '#404349',
    fontWeight: '200',
    margin: '17px 0',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  marginBottom: {
    marginBottom: '100px',
  },
  bulletFilled: {
    minHeight: '8px',
    minWidth: '8px',
    borderRadius: '8px',
    backgroundColor: theme.colors.textBlue,
    margin: '20px',
  },
}));

type Props = {};

function TermsOfService(props: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div>
      <div className={classes.pageHeading}>Terms of Service</div>
      <div className={classes.pageSubheading}>
        Policies that apply to your account and use of this site
      </div>
      <div className={classes.lastUpdated}>
        Last revised on the 1st Jan 2023, and effective as of that date
      </div>
      <div className={classes.textHeading}>About Us</div>
      <div className={classes.text}>
        The Training and Compliance Hub Limited (“TTC”, “we” or “us”) is
        registered in England & Wales with company number 10849230. Our
        registered address is Plesman House, 2a Cains Lane, Feltham, Middlesex,
        TW14 9RL.
      </div>
      <div className={classes.textHeading}>
        Course Booking Terms & Conditions
      </div>
      <div className={classes.text}>
        On receipt of a booking, we will send joining instructions and a VAT
        invoice which shall be payable within 7 working days. We accept most
        Debit and Credit Cards, BACS payments and Cheques (payable to The
        Training and Compliance Hub Limited).
        <br></br>
        <br></br>Certificates are only issued on receipt of full payment and we
        reserve the right to cancel, postpone or re-schedule any course.
        <br></br>
        <br></br>If you cancel or move to another course within 14 days of the
        course start date, a £35 + VAT fee will apply. If a delegate fails to
        turn up on the day of course, the full course fee shall be payable.
        <br></br>
        <br></br>Online courses are purchased directly on our website. A
        username and password will be automatically generated and an account
        created for the candidate to compete their course/s. Online courses are
        non-refundable once they have been purchased however, TTC reserves the
        right to offer a credit or voucher in special circumstances.
      </div>
      <div className={classes.textHeading}>Intellectual Property Rights</div>
      <div className={classes.text}>
        Unless otherwise explicitly stated, we are the owner of all intellectual
        property rights in our site and in the material published, including but
        not limited to any concepts, ideas, methods, procedures, processes,
        know-how, techniques, programs, publications, models, products,
        templates, technologies, software designs, art work, graphics and
        information on or described in our site. All such rights are reserved.
        <br></br>
        <br></br>Users are encouraged to distribute content (e.g. via link on a
        social network) provided that:
      </div>
      <div className={classes.row}>
        <div className={classes.bulletFilled} />
        <div className={classes.text}>
          All copyright, trademark and similar notices are retained
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.bulletFilled} />
        <div className={classes.text}>
          The content does not imply that we are providing a testimonial or
          endorsement of an organisation, its products or services.
        </div>
      </div>
      <div className={classes.text}>
        You must not modify, reproduce, publicly display, distribute or use
        without explicit written permission from the appropriate content or
        material provider (including third-party links).
      </div>
      <div className={classes.textHeading}>No Reliance on Information</div>
      <div className={classes.text}>
        The content on our site is provided for general information only. It is
        not intended to amount to advice or serve as a substitute for any audit,
        advisory or other professional advice, consultation or service.
      </div>
      <div className={classes.textHeading}>Our Liability</div>
      <div className={classes.text}>
        To the extent permitted by law, we exclude all conditions, warranties,
        representations or other terms which may apply to our site or any
        content on it, whether express or implied, included but not limited to
        that the site will be uninterrupted, without delay, error-free,
        omission-free, or free of viruses.
        <br></br>
        <br></br>TTC, its related partners, directors, agents or employees will
        not be liable to any user for any loss or damage arising under or in
        connection with but not limited to:
      </div>
      <div className={classes.row}>
        <div className={classes.bulletFilled} />
        <div className={classes.text}>
          Use of, or inability to use, our site;
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.bulletFilled} />
        <div className={classes.text}>
          or use of, or reliance on, any content displayed on our site.{' '}
        </div>
      </div>
      <div className={classes.text}>
        We have no liability to you for any loss of profit, data, sales or
        revenue, loss of business, business interruption or business opportunity
        or any indirect, incidental, special, exemplary, punitive, consequential
        or other loss or damage.
      </div>
      <div className={classes.textHeading}>Viruses</div>
      <div className={classes.text}>
        We do not guarantee that our site will be secure or free from bugs or
        viruses and will not be liable for any loss or damage caused by a virus
        either through our site or third-party link.
        <br></br>
        <br></br>You are responsible for configuring your information
        technology, computer programmes and platform in order to access our
        site. You should use your own virus protection software.
        <br></br>
        <br></br>You must not misuse our site by knowingly introducing viruses,
        trojans, worms, logic bombs or other material which is malicious or
        technologically harmful. You must not attack our site via a
        denial-of-service attack or a distributed denial-of service attack. By
        breaching this provision, you would commit a criminal offence under the
        Computer Misuse Act 1990.
      </div>
      <div className={classes.textHeading}>Linking to Our Site</div>
      <div className={classNames(classes.text, classes.marginBottom)}>
        Where our site contains links to other sites and resources provided by
        third parties, these links are provided for your information and
        convenience only. We have no control over, and are not responsible for,
        the contents of those sites or resources.
        <br></br>
        <br></br>You must not establish any link without our explicit
        permission. We reserve the right to withdraw linking permission without
        notice.
      </div>
    </div>
  );
}

export default TermsOfService;
