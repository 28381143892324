/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserType = "delegate" | "individual" | "manager" | "%future added value";
export type CourseDetailsPage_user = {
    readonly firstName: string;
    readonly type: UserType;
    readonly " $refType": "CourseDetailsPage_user";
};
export type CourseDetailsPage_user$data = CourseDetailsPage_user;
export type CourseDetailsPage_user$key = {
    readonly " $data"?: CourseDetailsPage_user$data;
    readonly " $fragmentRefs": FragmentRefs<"CourseDetailsPage_user">;
};



const node: ReaderFragment = ({
    "kind": "Fragment",
    "name": "CourseDetailsPage_user",
    "type": "User",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
        },
        {
            "kind": "ScalarField",
            "alias": null,
            "name": "type",
            "args": null,
            "storageKey": null
        }
    ]
} as any);
(node as any).hash = 'ec36471b37ff6ff57f5167b8466cc4a9';
export default node;
