import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { Theme } from 'helpers/theme';
import ContactPage, { ContactDetails } from 'components/Overview/ContactPage';
import { graphql, commitMutation } from 'react-relay';
import environment from 'api/environment';
import { ContactUs_ContactAdminMutationResponse } from './__generated__/ContactUs_ContactAdminMutation.graphql';
import { GraphError } from 'types/general';

const useStyles = createUseStyles((theme: Theme) => ({
  contactRoot: {
    width: '100%',
  },
  centerer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  centered: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    maxWidth: '622px',
    margin: '40px 0 120px 0',
  },
  page: {
    marginTop: '100px',
    width: '100%',
    maxWidth: '622px;',
  },
  infoBox: {
    maxWidth: '175px;',
  },
  heading: {
    fontSize: theme.fontSizes.large,
    color: theme.colors.primaryBlack,
    fontWeight: '800',
    marginBottom: '15px',
  },
  text: {
    fontSize: theme.fontSizes.small,
    color: theme.colors.textGrey,
    marginBottom: '15px',
  },
}));

const emptyContactDetails: ContactDetails = {
  firstName: '',
  lastName: '',
  companyName: '',
  email: '',
  phone: '',
  extra: '',
};

const contactAdmin = graphql`
  mutation ContactUs_ContactAdminMutation(
    $recaptchaToken: String!
    $firstName: String!
    $lastName: String!
    $companyName: String!
    $email: String!
    $phone: String!
    $details: String!
  ) {
    contactAdmin(
      input: {
        recaptchaToken: $recaptchaToken
        firstName: $firstName
        lastName: $lastName
        companyName: $companyName
        email: $email
        phone: $phone
        details: $details
      }
    )
  }
`;

type Props = {};

function ContactUs(props: Props) {
  const classes = useStyles();
  const [showModal, setShowModal] = React.useState(false);
  const [modalHeader, setModalHeader] = React.useState('');
  const [modalText, setModalText] = React.useState('');

  const submitForm = (e: any, details: ContactDetails, token: string) => {
    e.preventDefault();
    const variables = {
      recaptchaToken: token,
      firstName: details.firstName,
      lastName: details.lastName,
      companyName: details.companyName,
      email: details.email,
      phone: details.phone,
      details: details.extra,
    };

    if (token) {
      commitMutation(environment, {
        mutation: contactAdmin,
        variables,
        onCompleted: (
          response: ContactUs_ContactAdminMutationResponse,
          errors: GraphError[],
        ) => {
          if (errors) {
            // display errors
            console.error(errors[0].extensions?.message);
            onError();
            return;
          }

          console.log('Response received from server.', response, errors);
          if (response.contactAdmin) {
            onSuccess();
          } else {
            onError();
          }
        },
        onError: (err: Error) => {
          console.log(err.message || err.name);
          onError();
        },
      });
    } else {
      onError();
    }
  };

  const onError = () => {
    setModalHeader('Something went wrong...');
    setModalText(
      "Please check all fields have been entered, and you've completed the Recaptcha test",
    );
    setShowModal(true);
  };

  const onSuccess = () => {
    setModalHeader('Thank you for contacting us!');
    setModalText('Our team will reply to your request as soon as possible');
    setShowModal(true);
  };

  return (
    <div className={classes.contactRoot}>
      <div className={classes.centerer}>
        <ContactPage
          className={classes.page}
          contactDetails={emptyContactDetails}
          showModal={showModal}
          setShowModal={(show: boolean) => setShowModal(show)}
          modalHeader={modalHeader}
          modalText={modalText}
          onHelp={() => console.log('Help')}
          onSubmit={submitForm}
        />
        <div className={classes.centered}>
          <div className={classes.infoBox}>
            <div className={classes.heading}>TTC Hub</div>
            <div className={classes.text}>
              Plesman House, 2a Cains Lane, Feltham, Middlesex, TW14 9RL
            </div>
            <div className={classes.text}>+44 (0)20 3488 2703</div>
          </div>
          <div className={classes.infoBox}>
            <div className={classes.heading}>Prefer Email?</div>
            <div className={classes.text}>
              Contact us direct on{' '}
              <a href="mailto:admin@ttc-hub.com">admin@ttc-hub.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
