import * as React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import classNames from 'classnames';
import { Theme } from 'helpers/theme';
import FloatingVideo from 'components/core/VideoPlayer/FloatingVideo';
import { Row } from 'components/core/ImageWithText';
import Spacer from 'sharedComponents/core/Spacers/Spacer';
import PageHeader, { ButtonLink } from 'components/core/PageHeader';
import CourseSearch, { Tab } from 'components/CourseSearch';
import TrustedCard from 'components/core/Cards/TrustedCard';
import FourPanel from 'components/core/FourPanel';
import { Panel } from 'components/core/FourPanel/FourPanel';
import PeopleCurve from 'components/core/Curve/PeopleCurve';
import ExpandableListView, {
  ExpandItemType,
} from 'components/Misc/ExpandableListView';
import PageMargin from 'components/core/PageMargin';
import { createFragmentContainer, graphql, fetchQuery } from 'react-relay';
import { Courses_courses } from './__generated__/Courses_courses.graphql';
import { Courses_category } from './__generated__/Courses_category.graphql';
import { Courses_secondaryCategories } from './__generated__/Courses_secondaryCategories.graphql';
import environment from 'api/environment';
import { useRouter } from 'found';

const useStyles = createUseStyles((theme: Theme) => ({
  courseRoot: {
    width: '100%',
    backgroundColor: theme.colors.primaryWhite,
  },
  heading: {
    fontSize: 32,
    color: theme.colors.primaryBlack,
    fontWeight: 800,
    padding: '20px 0px',
    textAlign: 'center',
  },
  text: {
    fontSize: theme.fontSizes.large,
    fontWeight: 500,
    textAlign: 'center',
    maxWidth: '750px',
  },
  courseSearch: {
    paddingBottom: '30px',
    backgroundColor: '#F7F9FB',
  },
  margin: {
    margin: '80px 0',
  },
  smallMargin: {
    margin: '40px 0',
  },
  marginBottom: {
    marginBottom: '40px',
  },
}));

const defaultTab: Tab = {
  name: 'All Courses',
  uuid: '',
};

const defaultImagePanels: Panel[] = [
  {
    title: 'Register',
    desciption:
      'Whether as a company or an individual who wishes to enhance their career, firstly register with us and create an account.',
    imageURL: require('assets/StepOne.png'),
  },
  {
    title: 'Book a course',
    desciption:
      'Book members of your team, or yourself onto one of our many courses, whether this is online, classroom or hybrid.',
    imageURL: require('assets/StepTwo.png'),
  },
  {
    title: 'Course completion',
    desciption:
      'A member of your team, or you, can enjoy the experience of one of our courses furthering knowledge and helping career development.',
    imageURL: require('assets/StepThree.png'),
  },
  {
    title: 'Certification and records',
    desciption:
      'On successful completion delegates will be certified and relevant records will be logged on their account for future reference.',
    imageURL: require('assets/StepFour.png'),
  },
];

const defaultStack: Row[] = [
  {
    iconName: 'CourseCertificates',
    text:
      'All of our friendly and knowledgable team are available via email and live chat.',
    link: { title: 'World Class 24x7 Support', link: '/' },
  },
  {
    iconName: 'CourseCertificates',
    text:
      'Stay tuned for regular webinars and live QA sessions with the TTC team.',
    link: { title: 'Webinars and Live Sessions', link: '/' },
  },
  {
    iconName: 'CourseCertificates',
    text:
      'Got a question that needs an immediate answer? Try our knowledge base.',
    link: { title: 'Knowledge Base', link: '/' },
  },
];

const getMore = async (
  categoryUUID: string,
  offset: number,
  role: string,
  timeframe: string,
  secondaryCategoryUUID?: string,
) => {
  const query = graphql`
    query CoursesQuery(
      $categoryUUID: UUID
      $role: String
      $timeframe: String
      $secondaryCategoryUUID: UUID
      $offset: Int!
    ) {
      courses(
        filter: {
          categoryUUID: $categoryUUID
          role: $role
          timeframe: $timeframe
          secondaryCategoryUUID: $secondaryCategoryUUID
        }
        page: { limit: 5, offset: $offset }
        orderBy: { ascending: false, field: "priority"}
      ) {
        edges {
          ident: id
          name
          price
          excerpt
          introduction
          bannerImageURL
        }
        pageInfo {
          total
          offset
          limit
          given
        }
      }
    }
  `;

  const variables = {
    offset,
    categoryUUID,
    secondaryCategoryUUID: secondaryCategoryUUID || undefined,
    role: (role != "" && role != "Show All") ? role : undefined,
    timeframe: (timeframe != "" && timeframe != "Show All") ? timeframe : undefined,
  };
  const data = (await fetchQuery(environment, query, variables)) as {
    courses: Courses_courses;
  };

  if (!data || !data.courses || !data.courses.edges || !data.courses.pageInfo) {
    console.error('Could not get data', data);
    return undefined;
  }

  return data.courses;
};

type Props = {
  courses: Courses_courses;
  category: Courses_category;
  secondaryCategories: Courses_secondaryCategories;
};

function Courses({ courses, category, secondaryCategories }: Props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { router, match } = useRouter();
  const [tabs, setTabs] = React.useState<Tab[]>([defaultTab]);
  const [firstFilter, setfirstFilter] = React.useState<string>("");
  const [secondFilter, setsecondFilter] = React.useState<string>("");
  const [currentCourses, setCourses] = React.useState<Courses_courses['edges']>(
    courses.edges,
  );
  const [selectedTab, setSelectedTab] = React.useState<Tab>(defaultTab);

  React.useEffect(() => {
    setCourses(courses.edges);
    if (match.location.query.role && match.location.query.role != undefined) {
      setfirstFilter(match.location.query.role)
    }

    if (match.location.query.timeframe && match.location.query.timeframe != undefined) {
      setsecondFilter(match.location.query.timeframe)
    }

    const getTabs = async () => {
      let tabs: Tab[] = [];
      if (secondaryCategories?.edges) {
        for (const tab of secondaryCategories.edges) {
          if (tab?.uuid) {
            // Don't show tabs with no courses
            const courses = await getMore(category.uuid ?? '', 0, firstFilter, secondFilter, tab.uuid);
            if (courses?.edges && courses.edges.length > 0) {
              tabs.push(tab);
            }
          }
        }
      }

      // Sort alphabetically
      tabs.sort((tabA, tabB) => {
        var a = tabA.name.toUpperCase();
        var b = tabB.name.toUpperCase();
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });

      if (match.params.secondaryCategoryUUID) {
        tabs.forEach((tab) => {
          if (tab.uuid == match.params.secondaryCategoryUUID) {
            setSelectedTab(tab);
          }
        });
      } else {
        setSelectedTab(defaultTab);
      }
      setTabs([defaultTab, ...tabs]);
    };

    getTabs();
  }, [courses, secondaryCategories, match]);

  const courseItems = (currentCourses || []).map((course) => ({
    id: course?.ident,
    title: course?.name ?? '',
    description: course?.excerpt ?? '',
    price: `£${course?.price}`,
    type: category.name,
    colour: category.color,
    imageURL: course?.bannerImageURL ?? '',
    viewCourse: () => {
      router.push('/course/' + course?.ident);
    },
    addToBasket: () => {},
  }));

  const onChangeTab = async (tab: Tab) => {
    setfirstFilter("")
    setsecondFilter("")
    router.push(`/courses/${category.uuid}/${tab.uuid}`);
  };

  return (
    <div className={classes.courseRoot}>
      <PageHeader
        title={`${category.name} Courses`}
        description={category.description}
        archetype="buttons"
        buttons={undefined}
        history={['Courses', category.name]}
      />
      <Spacer spacing={4} vertical />
      <PageMargin>
        <div className={classNames(classes.heading, classes.smallMargin)}>
          Our four-step training process
        </div>
        <FourPanel
          className={classes.marginBottom}
          panels={defaultImagePanels}
          noBorders
        />
        <div className={classes.margin}>
          <div className={classes.heading}>Explore {category.name} Courses</div>
          <div className={classes.text}>
            If you are unsure which course to book, give us a call!
          </div>
        </div>
      </PageMargin>
      <CourseSearch
        className={classes.courseSearch}
        tabs={tabs}
        courses={courseItems}
        firstFilter={firstFilter}
        secondFilter={secondFilter}
        selectedTab={selectedTab}
        onChangeTab={onChangeTab}
        moreToShow={courses.pageInfo?.total != currentCourses?.length}
        totalCourses={courses.pageInfo?.total}
        onMore={async () => {
          const more = await getMore(
            category.uuid ?? '',
            (currentCourses ?? []).length,
            firstFilter,
            secondFilter,
            selectedTab !== defaultTab ? selectedTab.uuid : undefined,
          );
          if (more) {
            if (!currentCourses || !more.edges) return;
            setCourses([...currentCourses, ...more.edges]);
          }
        }}
      />
      <PageMargin>
        <div className={classNames(classes.heading, classes.margin)}>
          Frequently Asked Questions about {category.name}
        </div>
        <ExpandableListView
          data={
            category.faqItems?.map((faqItem, i) => ({
              id: i,
              title: faqItem.question,
              description: faqItem.answer,
              isExpanded: false,
            })) ?? []
          }
        />
      </PageMargin>
      {/* <PageMargin>
        <div className={classes.margin}>
          <FloatingVideo
            height={352}
            width={628}
            source={require('assets/Stock_Video.mp4')}
            author={{
              name: 'Kristian Durhuus',
              title: 'Chief Executive Officer',
              quote:
                'TTC Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore .',
            }}
          />
        </div>
      </PageMargin> */}
      <PeopleCurve stack={defaultStack} />
    </div>
  );
}

export default createFragmentContainer(Courses, {
  courses: graphql`
    fragment Courses_courses on CoursePage {
      edges {
        ident: id
        name
        price
        excerpt
        introduction
        bannerImageURL
      }
      pageInfo {
        total
        offset
        limit
        given
      }
    }
  `,
  category: graphql`
    fragment Courses_category on Category {
      uuid
      name
      description
      color
      faqItems {
        question
        answer
      }
    }
  `,
  secondaryCategories: graphql`
    fragment Courses_secondaryCategories on SecondaryCategoryPage {
      edges {
        uuid
        name
      }
    }
  `,
});
